<template>
  <f7-popup class="addNewVideo" style="height: 100%; --f7-sheet-bg-color: #fff" :tablet-fullscreen="true"
    :backdrop="false" :close-by-backdrop-click="false" :close-on-escape="false">
    <f7-view>
      <f7-page>
        <f7-navbar no-shadow>
          <div class="left"></div>
          <div class="title">{{ $t("addvideo.addVideoOr") }}</div>
          <div class="right">
            <f7-link popup-close icon-f7="xmark_circle"></f7-link>
          </div>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block-title medium>{{ $t("addvideo.addVideoOr") }}</f7-block-title>
            <f7-block-header>{{ $t("addvideo.pasteYourVideo") }}<br />
              <br />src: <code><i>https://www.youtube.com/embed/iX_QyjdctsQ</i></code><br />

              <br />{{ $t("addvideo.orIframe") }}
              <i>&lt iframe title="Add video frame"
                src="https://www.facebook.com/plugins/video.php?..." frameborder="0"
                &lt/iframe &gt</i>
            </f7-block-header>
            <f7-list dividers-ios strong-ios media-list>
              <f7-list-input :label="$t('addvideo.myVideoLink')" floating-label type="textarea" name="videoLink" resizable
                :placeholder="$t('addvideo.myVideoLink')" :value="videoUrl" @input="videoUrl = $event.target.value"
                @keypress.enter.native="addVideo()" @change="addVideo()" inputStyle="{'margin-right':'0px'}"
                :error-message="'addvideo.enterVideoLink'" required validate>
                <f7-link @click="addVideo()" class="margin-right-half" :style="{
                  position: 'relative',
                  top: '8px',
                }" icon-color="auto" slot="content-end" icon-aurora="f7:plus_circle" icon-ios="f7:plus_circle"
                  icon-md="material:add_circle"></f7-link>
              </f7-list-input>
              <f7-list-input :label="$t('addvideo.myVideoName')" floating-label type="textarea" name="videoName" resizable
                :placeholder="$t('addvideo.myVideoName')" @keypress.enter.native="addVideo()" :value="videoName"
                @input="videoName = $event.target.value" :error-message="'addvideo.enterVideoName'" clear-button>
              </f7-list-input>
            </f7-list>
            <div v-for="(video, index) in this.videosUrl" :key="index"
              class="grid grid-cols-1 medium-grid-cols-2 large-grid-cols-3">
              <f7-card class="videoContainer" :style="{
                position: 'relative',
                overflow: 'hidden',
                'padding-top': '56.25%',
              }">
                <span :style="{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0,
                }" v-if="video.url.includes('iframe')" v-html="video.url"></span>
                <iframe v-else class="videoIframe" :style="{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0,
                }" :title="video.name" :src="video.url" frameborder="0" loading="lazy"
                  allow="accelerometer; autoplay; microphone; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
                <f7-link raised :style="{
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                  color: 'red',
                }" icon-f7="multiply_square_fill" @click="removeVideo(index)"></f7-link>
              </f7-card>
              <f7-card-footer>{{ video.name }}</f7-card-footer>
            </div>
            <f7-button v-if="this.videosUrl && this.videosUrl.length > 0" raised fill ripple large color="red"
              class="buttons" @click="saveVideos()">{{ $t("addvideo.saveVideo") }}</f7-button>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
import {
  f7Popup,
  f7Toolbar,
  f7Link,
  f7PageContent,
  f7BlockTitle,
  f7BlockHeader,
  f7List,
  f7ListInput,
  f7Card,
  f7Button,
  f7CardFooter,
  f7,
  theme,
} from "framework7-vue";
import { attachDocs } from "../js/mixins/attachDocs.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    f7Popup,
    f7Toolbar,
    f7Link,
    f7PageContent,
    f7BlockTitle,
    f7BlockHeader,
    f7List,
    f7ListInput,
    f7Card,
    f7Button,
    f7CardFooter,
  },
  mixins: [attachDocs],

  data() {
    return { theme, f7, videoUrl: "", videoName: "" };
  },
  mounted() {
    const self = this;
  },

  methods: {},

  computed: {
    ...mapState("account", ["primaryAccount", "businessAccount"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "videoService",
      "videosUrl",
    ]),
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "videosUrl",
      "video",
      "isDeskTop",
      "videoService",
    ]),
  },
};
</script>
