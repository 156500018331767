<template>
  <f7-page hide-bars-on-scroll @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ $t("recipients.addNewRecipient") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block-title medium v-html="$t('common.searchAvocashUser')"></f7-block-title>
        <userslist> </userslist>
        <f7-list dividers-ios strong-ios>
          <f7-list-group v-if="this.searchedUser">
            <f7-chip class="margin" style="display: flex; justify-content: center" v-if="this.allrecipients &&
              this.allrecipients.find(
                ({ userRecipientId }) => userRecipientId === this.searchedUser.userId
              )
              " color="red">{{
    $t("recipientnew.isAlreadyARecipient", {
      myrecipient: this.searchedUser.name,
    })
  }}</f7-chip>

            <f7-list-item v-else no-chevron link="#" swipeout @click="saveNewRecipient()" :title="searchedUser.name"
              :after="'+' + searchedUser.phoneCode + '' + searchedUser.phoneNumber" :footer="searchedUser.email">
              <f7-swipeout-actions right>
                <f7-swipeout-button @click="saveNewRecipient()" color="red" overswipe>{{ $t("recipients.addRecipient") }}
                </f7-swipeout-button>
              </f7-swipeout-actions>
              <img v-if="searchedUser.logo !== null" :src="`data:image/png;base64,${searchedUser.logo}`" width="40"
                style="height: 48px; width: 48px; border-radius: 25px" slot="media" />
              <f7-badge v-else slot="media" color="auto"
                style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">{{
                  $filtersGetInitial.getInitial(searchedUser.name) }}</f7-badge>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
        <span v-if="(f7.cordova || this.contactsIsSupported) && this.searchedUser === ''">
          <f7-block-title medium v-html="$t('common.pickFromAdressBook')"></f7-block-title>
          <f7-list strong-ios dividers-ios>
            <f7-f7-list-item link="#" :title="$t('recipientnew.fillInThe')"><template #media><f7-link
                  class="input-icon2 padding-right-half elevation-2" @click="selectContact()" icon-color="auto"
                  icon-ios="f7:person_2_square_stack" icon-md="material:contacts"></f7-link></template></f7-f7-list-item>
          </f7-list>
        </span>
        <f7-block-title medium style="white-space: normal; word-wrap: break-word" v-if="this.searchedUser === ''">{{
          $t("recipientnew.fillInThe") }}</f7-block-title>
        <f7-list form-store-data id="recipientadd" strong-ios dividers-ios v-if="this.searchedUser === ''">
          <f7-list-group>
            <f7-list-input :label="$t('common.firstName')" floating-label type="text" name="firstName"
              :placeholder="$t('common.firstName')" :value="this.firstName" @input="firstName = $event.target.value"
              :error-message="$t('common.provideFirstName')" required validate clear-button></f7-list-input>
            <f7-list-input :label="$t('common.lastName')" floating-label type="text" name="lastname"
              :placeholder="$t('common.lastName')" :value="this.lastName" @input="lastName = $event.target.value"
              :error-message="$t('common.provideLastName')" required validate clear-button></f7-list-input>
            <f7-list-input :label="$t('common.phoneNumber')" type="tel" name="telephone" input-id="phoneNumber"
              :value="this.telephone" @input="telephone = $event.target.value"
              :error-message="$t('common.providePhoneNumber')" required validate></f7-list-input>
            <f7-list-item :title="$t('common.selectCountry')" class="selectCountry item-label" smart-select
              :smart-select-params="{
                openIn: 'page',
                searchbar: true,
                searchbarPlaceholder: $t('common.findCountry'),
                closeOnSelect: true,
              }" style="display: none">
              <select name="country" v-model="selectedCountry">
                <optgroup v-for="(countryList, continentName, index) in this.countries" :label="continentName"
                  :key="index">
                  <option v-for="country in countryList" :key="country.id" :value="country.countryCode2"
                    :data-option-image="'https://flagcdn.com/' + country.countryCode2.toLowerCase() + '.svg'
                      " width="24">
                    {{ country.name }} {{ country.countryCode2 }} +{{ country.dialCode }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>

            <f7-block class="no-margin-vertical" v-if="this.telephone && this.telephone.length > 0">
              <p v-if="this.phoneNumberDetail !== null" style="font-size: 10px; font-style: italic; color: red">
                {{ this.phoneNumberDetail.carrier }}
                {{ this.phoneNumberDetail.geocoder }}
              </p>
              <p v-if="this.mobilePhoneError !== null" style="font-size: 10px; font-style: italic; color: red">
                {{ this.mobilePhoneError }}
              </p>
            </f7-block>
            <f7-list-input :label="$t('common.email')" floating-label type="email" name="email"
              :placeholder="$t('common.email')" :value="this.email" @input="email = $event.target.value"
              :error-message="$t('common.provideEmail')" required validate clear-button></f7-list-input>
            <f7-list-input :label="$t('common.address')" floating-label type="text" name="address"
              :placeholder="$t('common.address')" @input="address = $event.target.value"
              :error-message="$t('common.provideAddress')" required validate clear-button></f7-list-input>
          </f7-list-group>
        </f7-list>
        <f7-toolbar tabbar bottom :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }" v-if="(this.allrecipients &&
            !this.allrecipients.find(
              ({ userRecipientId }) => userRecipientId === this.searchedUser.userId
            )) ||
          !this.searchedUser
          ">
          <f7-link raised fill large ripple class="margin-horizontal link buttons" bg-color="red"
            @click="saveNewRecipient()" strong text-color="white">{{ $t("common.save") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
    <selectContact :type="'contact'"></selectContact>
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Chip,
  f7SwipeoutActions,
  f7SwipeoutButton,
  f7Badge,
  f7BlockTitle,
  f7Block,
  f7List,
  f7ListGroup,
  f7ListInput,
  f7Toolbar,
  theme,
  f7,
} from "framework7-vue";
import UsersPage from "./users.vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import { telephoneUtils } from "../js/mixins/telephone.js";
import { cordovaPlugin } from "../js/mixins/cordovaPlugins.js";
import selectContact from "../components/selectContact.vue";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    userslist: UsersPage,
    navbarright: Navbarright,
    selectContact,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Chip,
    f7SwipeoutActions,
    f7SwipeoutButton,
    f7Badge,
    f7BlockTitle,
    f7Block,
    f7List,
    f7ListGroup,
    f7ListInput,
    f7Toolbar,
  },
  mixins: [telephoneUtils, cordovaPlugin],
  data() {
    return {
      theme,
      f7,
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      address: "",
      countryId: "",
      countryName: "",
      countryCode: "",
      phonecode: "",
      name: "",
      // country:'',
      countryPhoneCode: "",
      state: "",
      city: "",
      selectedCountry: "",
      newRecipient: {},
    };
  },

  mounted() {
    if (this.selectedContact === "") {
      return;
    }
    this.selectedCountry = this.$keycloak.authenticated
      ? this.profile.countryCode
      : this.currentUserCountry.countryCode2;
    this.firstName = this.selectedContact.firstName;
    lastName = this.searchedUser.lastName;
    this.name = this.selectedContact.name;
    this.email = this.selectedContact.email;
    this.phone = this.this.selectedContact.telephone;
  },

  computed: {
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "recipient",
      "userrecipientList",
    ]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["searchedUser"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "selectedContact",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },

    countries() {
      return _.groupBy(this.country, "continentName");
    },
    recipientCountry() {
      return _.find(this.country, ["countryCode2", this.selectedCountry]);
    },
  },

  methods: {
    saveNewRecipient() {
      const self = this;
      if (this.searchedUser) {
        this.newRecipient = {
          firstName: this.searchedUser.name.split(" ")[0],
          lastName: this.searchedUser.name.split(" ")[1],
          name: this.searchedUser.name,
          userRecipientId: this.searchedUser.userId,
          email: this.searchedUser.email,
          phone: this.searchedUser.phoneNumber,
          countryCode: this.searchedUser.countryCode,
        };
      } else {
        this.newRecipient = {
          firstName: this.firstName,
          lastName: this.lastName,
          name: `${this.firstName} ${this.lastName}`,
          email: this.email,
          phone: this.telephone,
          address: this.address,
          userRecipientId: null,
          countryCode: this.recipientCountry.countryCode2,
          countryName: this.recipientCountry.name,
        };
      }

      self.$store.dispatch("remit/recipientAdd", this.newRecipient).then((resp) => {
        f7.preloader.show("multi");
        setTimeout(() => {
          this.f7router.navigate(`/recipient/${this.recipient.name}`, {
            ignoreCache: true,
            reloadCurrent: true,
          });
          f7.preloader.hide();
        }, 1500);
        console.log(this.recipient);
      });
    },

    beforeOut() {
      const self = this;
      f7.$(".mainToolbar").css("display", "block");
    },
  },
};
</script>
