import { $ } from "dom7";
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
const path = import.meta.env.VITE_APP_URL;
export const transactAuth = {
  data() {
    return {
      theme,
      f7,
      FingerprintOptions: "",
      openloginScreen: "",
      biometricIsAvailable: false,
    };
  },
  mounted() {
    this.FingerprintOptions = {
      title: this.$t("common.biometricSignOnTitle"),
      description: this.$t("common.authenticate"),
      cancelButtonTitle: this.$t("common.cancel"),
      fallbackButtonTitle: f7.device.android
        ? this.$t("common.fallback")
        : this.$t("common.usePIN"),
      clientId: this.profile.username,
      clientSecret: this.profile.sub,
    };
    document.addEventListener("deviceready", this.onDeviceReady, false);
  },
  methods: {
    onDeviceReady() {
      window.Fingerprint.isAvailable(
        (result) => {
          console.log("Fingerprint available", result);
          this.biometricIsAvailable = true;
        },
        (error) => {
          console.log("Fingerprint not available", error);
          this.biometricIsAvailable = false;
        },
        { allowBackup: true }
      );
    },

    loginWithCordova() {
      Fingerprint.show(
        this.FingerprintOptions,
        this.successCallback,
        this.errorCallback
      );
    },

    selectCordovaOrPIN() {
      if (this.biometricIsAvailable) {
        this.loginWithCordova();
      } else {
        this.openAuthloginScreen();
      }
    },
    checkAuth() {
      this.openAuthloginScreen();
      // console.log("window.location", window.location);
      // if (window.location.href.indexOf("http://localhost") >= 0) {
      //   this.openAuthloginScreen();
      // } else {
      //   if (!window.PublicKeyCredential) {
      //     console.log("WebAuthn is not supported by this browser.");
      //     return this.selectCordovaOrPIN();
      //   }

      //   PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
      //     .then((available) => {
      //       if (available) {
      //         console.log(
      //           "User-verifying platform authenticator is available.",
      //           available
      //         );
      //         if (
      //           !this.userAccount.handle ||
      //           this.userAccount.handle.length === 0
      //         ) {
      //           console.log("User handle is not available, create passKey");
      //           this.createPasskey();
      //         } else {
      //           console.log("User handle is available, login");
      //           this.verifyPasskey();
      //         }
      //       } else {
      //         console.log(
      //           "User-verifying platform authenticator is not available."
      //         );
      //         this.selectCordovaOrPIN();
      //       }
      //     })
      //     .catch((error) => {
      //       console.error(
      //         "Error checking for user-verifying platform authenticator:",
      //         error
      //       );
      //       this.selectCordovaOrPIN();
      //     });
      // }
    },

    base64urlToUint8array(base64URL) {
      const base64 = base64URL.replace(/-/g, "+").replace(/_/g, "/");
      const padLen = (4 - (base64.length % 4)) % 4;
      return Uint8Array.from(
        atob(base64.padEnd(base64.length + padLen, "=")),
        (c) => c.charCodeAt(0)
      );
    },

    uint8arrayToBase64url(buffer) {
      const bytes = new Uint8Array(buffer);
      let string = "";
      bytes.forEach((b) => (string += String.fromCharCode(b)));

      const base64 = btoa(string);
      return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
    },

    createPasskey() {
      self = this;
      this.axios({
        url: `${path}webauthn/register`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`,
        },
        data: this.userAccount,
      })
        .then((r) => r.data)
        .then((credentialCreateJson) => ({
          publicKey: {
            ...credentialCreateJson.publicKey,
            challenge: this.base64urlToUint8array(
              credentialCreateJson.publicKey.challenge
            ),
            user: {
              ...credentialCreateJson.publicKey.user,
              id: this.base64urlToUint8array(
                credentialCreateJson.publicKey.user.id
              ),
              displayName: credentialCreateJson.publicKey.user.displayName,
            },
            // pubKeyCredParams: [
            //   { alg: -7, type: "public-key" },
            //   { alg: -8, type: "public-key" },
            //   { alg: -35, type: "public-key" },
            //   { alg: -36, type: "public-key" },
            //   { alg: -257, type: "public-key" },
            //   { alg: -258, type: "public-key" },
            //   { alg: -259, type: "public-key" },
            // ],
            excludeCredentials:
              credentialCreateJson.publicKey.excludeCredentials.map(
                (credential) => ({
                  ...credential,
                  id: this.base64urlToUint8array(credential.id),
                })
              ),
            extensions: credentialCreateJson.publicKey.extensions,
          },
        }))
        .then((credentialCreateOptions) =>
          navigator.credentials.create(credentialCreateOptions)
        )
        .then((publicKeyCredential) => ({
          type: publicKeyCredential.type,
          id: publicKeyCredential.id,
          response: {
            attestationObject: this.uint8arrayToBase64url(
              publicKeyCredential.response.attestationObject
            ),
            clientDataJSON: this.uint8arrayToBase64url(
              publicKeyCredential.response.clientDataJSON
            ),
            transports:
              (publicKeyCredential.response.getTransports &&
                publicKeyCredential.response.getTransports()) ||
              [],
          },
          clientExtensionResults:
            publicKeyCredential.getClientExtensionResults(),
        }))
        .then((encodedResult) => {
          console.log("encodedResult", encodedResult);
          return this.axios({
            url: `${path}webauthn/register/finish`,
            method: "POST",
            params: {
              credname: f7.device.cordova
                ? ` ${this.device.model}`
                : `${navigator.userAgentData.platform}`,
              credential: JSON.stringify(encodedResult),
              userId: this.$keycloak.subject,
            },
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          });
        })
        .then((response) => {
          if (response.data != true) {
            this.openAuthloginScreen();
          }
          this.$store.dispatch("account/loadAccount", this.profile.sub);
          setTimeout(() => {
            if (this.userAccount && this.userAccount.handle.length > 0)
              this.verifyPasskey();
          }, 1000);
          console.log("register/finish response", response);
        })
        .catch((error) => {
          console.log("error", error);
          this.openAuthloginScreen();
        });
    },

    verifyPasskey() {
      self = this;
      // Retrieve credentials from server
      this.axios({
        url: `${path}webauthn/login`,
        method: "POST",
        params: {
          userId: this.$keycloak.subject,
        },
        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`,
        },
      })
        .then((r) => r.data)
        .then((credentialGetJson) => ({
          publicKey: {
            ...credentialGetJson.publicKey,
            allowCredentials:
              credentialGetJson.publicKey.allowCredentials &&
              credentialGetJson.publicKey.allowCredentials.map(
                (credential) => ({
                  ...credential,
                  id: this.base64urlToUint8array(credential.id),
                })
              ),
            challenge: this.base64urlToUint8array(
              credentialGetJson.publicKey.challenge
            ),

            extensions: credentialGetJson.publicKey.extensions,
          },
        }))
        .then((credentialGetOptions) =>
          navigator.credentials.get(credentialGetOptions)
        )
        .then((publicKeyCredential) => ({
          type: publicKeyCredential.type,
          id: publicKeyCredential.id,

          response: {
            authenticatorData: this.uint8arrayToBase64url(
              publicKeyCredential.response.authenticatorData
            ),
            clientDataJSON: this.uint8arrayToBase64url(
              publicKeyCredential.response.clientDataJSON
            ),
            signature: this.uint8arrayToBase64url(
              publicKeyCredential.response.signature
            ),
            userHandle:
              publicKeyCredential.response.userHandle &&
              this.uint8arrayToBase64url(
                publicKeyCredential.response.userHandle
              ),
          },
          clientExtensionResults:
            publicKeyCredential.getClientExtensionResults(),
        }))
        .then((encodedResult) => {
          console.log("encodedResult", encodedResult);
          this.axios({
            url: `${path}webauthn/login/finish`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
            params: {
              credential: JSON.stringify(encodedResult),
              userId: this.$keycloak.subject,
            },
          })
            .then((r) => r.data)
            .then((logingresponse) => {
              console.log("login/finish logingresponse", logingresponse);
              if (logingresponse == true) {
                self.successCallback();
              } else {
                self.errorCallback();
              }
            })
            .catch((err) => {
              // console.log(err.response.data);
              console.log(err);
              self.errorCallback();
            });
        })
        .catch((error) => {
          console.log(error);
          self.errorCallback();
        });
    },

    openAuthloginScreen() {
      f7.popup.open(".authLoginScreen");
    },

    errorCallback(error) {
      console.log(`Authentication invalid ${error.message}`);
      // if (this.isAuthAvailable && this.leftTrials < 4) {
      f7.dialog.alert(this.$t("common.autheticate with secretPIN"), () => {
        this.openAuthloginScreen();
      });
      // }
    },

    async successCallback() {
      console.log("successCallback", this.userPaymentProvider);
      if (this.userPaymentProvider === "stripe") {
        await this.$refs.stripeComponent.stripeValidate();
      } else if (this.userPaymentProvider === "bizao") {
        if (this.baseTransfer.payment_method === "MOBILE_MONEY") {
          await this.$store.dispatch(
            "apimoney/authorizeCashInMobileMoney",
            this.baseTransfer
          );
        } else if (this.baseTransfer.payment_method === "CREDIT_CARD") {
          await this.$store.dispatch(
            "apimoney/initiateCreditCardCashIn",
            this.baseTransfer
          );
        } else if (this.selected_method === "avocash") {
          await this.$store.dispatch(
            "apimoney/createTransferAuthorization",
            this.baseTransfer
          );
        }
      }
    },

    validatedDialog() {
      if (!this.toastIcon) {
        this.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-icons text-color-green border-color-gray color-green" style="font-size:150px">checkmark_circle</i>'
            : '<i class="material-icons text-color-green border-color-gray color-green" style="font-size:150px">check_circle_outline</i>',
          text: "",
          // this.scannedCard.status || this.QRtext.status,
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 3000,
        });
      }
      this.toastIcon.open();
    },
  },
  computed: {
    ...mapState("auth", ["isTablet", "profile", "isAuthAvailable", "device"]),
    ...mapState("account", ["userAccount"]),
  },
};
