<template>
  <f7-page hide-bars-on-scroll v-if="this.association" @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left
        :back-link="$t('common.backButton')"
        :back-link-show-text="false"
      ></f7-nav-left>
      <f7-nav-title>{{
        $t("associationedit.update", { assocType: this.name })
      }}</f7-nav-title>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout">
        <f7-card
          outline
          header-divider
          class="sticky with_content"
          v-if="
            this.association.associationMemberList &&
            this.association.associationMemberList.length > 0
          "
        >
          <f7-card-header>
            {{ $t("tontinedetail.participants") }}
          </f7-card-header>
          <f7-card-content>
            <membersComponent
              :entityId="this.association.id"
              :memberList="this.association.associationMemberList"
              :adminList="association.associationAdminList"
              :entityType="'asso'"
              :isAdmin="currentMemberIsAdmin"
              :mainUserId="association.mainAdminUserId"
            ></membersComponent>
          </f7-card-content>
        </f7-card>
      </div>
      <div class="main_layout">
        <f7-list
          dividers-ios
          strong-ios
          media-list
          accordion-list
          form-store-data
          id="createNewassociation"
        >
          <f7-list-group>
            <f7-card outline header-divider>
              <f7-card-header>{{ this.name }}</f7-card-header>
              <f7-card-content>
                <f7-list-item
                  v-if="
                    !!this.association.registrationNumber &&
                    this.association.type === 'association'
                  "
                  :header="$t('common.registrationNumber')"
                  :title="this.association.registrationNumber"
                >
                </f7-list-item>

                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title" style="color: gray">
                        {{ $t("common.collected") }}
                      </div>
                      <div class="item-title" style="color: gray">
                        {{ $t("common.balance") }}
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-title" style="font-weight: bold; color: green">
                        <span
                          v-html="
                            $filtersToCurrency.toCurrency(
                              (this.association.collectedAmount || 0).toFixed(2),
                              null,
                              this.association.currencyCode
                            )
                          "
                        ></span>
                      </div>
                      <div class="item-title" style="font-weight: bold; color: auto">
                        <span
                          v-html="
                            $filtersToCurrency.toCurrency(
                              (this.association.accountBalance || 0).toFixed(2),
                              null,
                              this.association.currencyCode
                            )
                          "
                        ></span>
                      </div>
                    </div>
                  </div>
                </li>
              </f7-card-content>
            </f7-card>
            <f7-card outline header-divider class="right not_sticky_with_content">
              <f7-card-header>
                {{
                  $t("associationdetail.selectCampaignprojectToUpdate", {
                    campaignType: this.getCampaignType.toLowerCase(),
                  })
                }}
              </f7-card-header>
              <f7-card-content>
                <li
                  @click="selectCampaign(campaign)"
                  v-for="(campaign, index) in this.sortedCampaignList"
                  :key="index"
                >
                  <a href="#" class="item-content" style="color: inherit">
                    <div class="item-media">
                      <f7-badge
                        color="auto"
                        style="height: 32px; width: 32px; font-size: 16px"
                        >{{ campaign.campaignNumber }}</f7-badge
                      >
                    </div>
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">{{ campaign.name }}</div>
                      </div>
                      <div class="item-title-row">
                        <div class="item-subtitle">
                          {{ $t("common.collected") }}
                        </div>
                        <div class="item-after">
                          <span
                            v-html="
                              $filtersToCurrency.toCurrency(
                                (campaign.collectedAmount || 0).toFixed(2),
                                null,
                                campaign.currencyCode
                              )
                            "
                          ></span>
                        </div>
                      </div>
                      <div class="item-title-row">
                        <div class="item-subtitle">
                          {{ $t("common.balance") }}
                        </div>
                        <div class="item-after">
                          <span
                            v-html="
                              $filtersToCurrency.toCurrency(
                                (campaign.accountBalance || 0).toFixed(2),
                                null,
                                campaign.currencyCode
                              )
                            "
                          ></span>
                        </div>
                      </div>
                      <div class="item-title-row">
                        <div class="item-footer">
                          {{
                            $t("common.startDate", {
                              startDate: campaign.startDate.substr(0, 10),
                            })
                          }}
                        </div>
                        <div class="item-footer">
                          {{
                            $t("common.endDate", {
                              endDate: campaign.endDate.substr(0, 10),
                            })
                          }}
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              </f7-card-content>
            </f7-card>

            <f7-block-title large>{{
              $t("associationdetail.updateCircleassociationDetails")
            }}</f7-block-title>
            <f7-list-input
              v-if="this.association.type === 'group'"
              :label="$t('common.name')"
              type="text"
              name="name"
              outline
              :value="name"
              @input="name = $event.target.value"
              :error-message="$t('common.nameInfo')"
              required
              validate
              maxlength="80"
              minlength="5"
            ></f7-list-input>
            <f7-list-input
              :label="$t('common.tag')"
              floating-label
              :value="tag"
              name="tag"
              outline
              @input="tag = $event.target.value"
              resizable
              required
              validate
              maxlength="80"
              type="text"
            />
            <f7-block-title class="item-header">{{
              $t("common.description")
            }}</f7-block-title>
            <f7-text-editor
              :value="description"
              name="associationDescription"
              @texteditor:change="(v) => (description = v)"
              resizable
              required
              validate
              type="texteditor"
              :style="
                f7.device.cordova || !this.isTablet
                  ? '--f7-text-editor-height: 150px'
                  : ''
              "
            />
          </f7-list-group>
        </f7-list>
        <myDocs :type="this.type"></myDocs>

        <f7-toolbar
          tabbar
          bottom
          no-hairline
          bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
        >
          <f7-link
            raised
            fill
            large
            class="link buttons"
            text-color="white"
            strong
            @click="updateAssociation()"
            >{{ $t("associationedit.update", { assocType: this.getType }) }}</f7-link
          >
        </f7-toolbar>
      </div>
      <div class="right_layout">
        <f7-card
          outline
          header-divider
          class="sticky with_content"
          v-if="this.sortedCampaignList && this.sortedCampaignList.length > 0"
        >
          <f7-card-header>
            {{
              $t("associationdetail.selectCampaignprojectToUpdate", {
                campaignType: this.getCampaignType.toLowerCase(),
              })
            }}
          </f7-card-header>
          <f7-card-content>
            <f7-list dividers-ios strong-ios media-list chevron-center>
              <li
                @click="selectCampaign(campaign)"
                v-for="(campaign, index) in this.sortedCampaignList"
                :key="index"
              >
                <a href="#" class="item-content" style="color: inherit">
                  <div class="item-media">
                    <f7-badge
                      color="auto"
                      style="height: 32px; width: 32px; font-size: 16px"
                      >{{ campaign.campaignNumber }}</f7-badge
                    >
                  </div>
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title">{{ campaign.name }}</div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-subtitle">
                        {{ $t("common.collected") }}
                      </div>
                      <div class="item-after">
                        <span
                          v-html="
                            $filtersToCurrency.toCurrency(
                              (campaign.collectedAmount || 0).toFixed(2),
                              null,
                              campaign.currencyCode
                            )
                          "
                        ></span>
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-subtitle">
                        {{ $t("common.balance") }}
                      </div>
                      <div class="item-after">
                        <span
                          v-html="
                            $filtersToCurrency.toCurrency(
                              (campaign.accountBalance || 0).toFixed(2),
                              null,
                              campaign.currencyCode
                            )
                          "
                        ></span>
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-footer">
                        {{
                          $t("common.startDate", {
                            startDate: campaign.startDate.substr(0, 10),
                          })
                        }}
                      </div>
                      <div class="item-footer">
                        {{
                          $t("common.endDate", {
                            endDate: campaign.endDate.substr(0, 10),
                          })
                        }}
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </div>
    </div>
    <f7-sheet
      v-if="this.currentCampaign !== ''"
      class="updateCampaign"
      swipe-to-close
      style="height: 100%; --f7-sheet-bg-color: #fff"
      :params="{
        backdrop: true,
        animate: true,
        swipeToClose: true,
        closeByBackdropClick: true,
        closeOnEscape: true,
      }"
    >
      >
      <f7-page>
        <f7-navbar>
          <div class="left"></div>
          <div class="title">
            {{
              $t("associationedit.update", {
                assocType: this.getCampaignType,
              })
            }}
          </div>
          <div class="right">
            <f7-link sheet-close icon-f7="xmark_circle"></f7-link>
          </div>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block-title large>
              {{
                $t("associationedit.update", {
                  assocType: this.getCampaignType,
                })
              }}
            </f7-block-title>
            <f7-list media-list strong-ios dividers-ios id="updateCampaign">
              <f7-list-group>
                <f7-list-input
                  required
                  validate
                  :placeholder="
                    $t('associationdetail.campaignprojectName', {
                      campaignType: this.getCampaignType,
                    })
                  "
                  type="text"
                  name="campaign"
                  outline
                  floating-label
                  :label="
                    $t('associationdetail.campaignprojectName', {
                      campaignType: this.getCampaignType,
                    })
                  "
                  :value="campaignTitle || this.currentCampaign.title"
                  @input="campaignTitle = $event.target.value"
                >
                </f7-list-input>
                <f7-list-input
                  v-if="this.association.type === 'group'"
                  outline
                  :label="$t('associationdetail.selectCampaignBeneficiary')"
                  type="select"
                  :placeholder="$t('associationdetail.selectCampaignBeneficiary')"
                  @input="campaignBeneficiary = $event.target.value"
                >
                  <option value="">
                    {{ $t("associationdetail.noBeneficiary") }}
                  </option>
                  <option
                    v-for="member in this.association.associationMemberList"
                    :key="member.id"
                    :value="member.userId"
                  >
                    {{ member.name }}
                  </option>
                </f7-list-input>
                <f7-list-item
                  title="Hide campaign from"
                  smart-select
                  :smart-select-params="{
                    openIn: this.isTablet ? 'popover' : 'sheet',
                    sheetSwipeToClose: true,
                  }"
                  :footer="$t('associationdetail.hideCampaignNote')"
                  v-if="this.association.type === 'group'"
                  outline
                >
                  <select name="hideCampaign" multiple v-model="hiddenFrom">
                    <option
                      v-for="member in this.association.associationMemberList"
                      :key="member.id"
                      :value="member.userId"
                    >
                      {{ member.name }}
                    </option>
                  </select>
                </f7-list-item>

                <f7-list-input
                  :label="
                    $t('associationedit.updateCampaignprojectEndDate', {
                      campaignType: this.getCampaignType.toLowerCase(),
                    })
                  "
                  type="datepicker"
                  :placeholder="this.currentCampaign.endDate.substr(0, 10)"
                  readonly
                  outline
                  :value="endDate"
                  @calendar:change="(value) => (endDate = value)"
                  :calendar-params="{
                    rangePicker: false,
                    closeOnSelect: true,
                    openIn: 'auto',
                    minDate: new Date(),
                    header: true,
                    footer: true,
                    dateFormat: 'dd/mm/yyyy',
                  }"
                  :info="this.daysLeft"
                ></f7-list-input>
                <li class="item-content item-input item-input-outline">
                  <div class="item-media">
                    {{ association.currencyCode }}
                  </div>
                  <div class="item-inner">
                    <div class="item-title item-label">
                      {{
                        $t("associationedit.campaignprojectAmountoptional", {
                          campaignType: this.getCampaignType,
                        })
                      }}
                    </div>
                    <div class="item-input-wrap">
                      <input
                        type="number"
                        name="campaignAmount"
                        :placeholder="$t('associationedit.campaignprojectAmountoptional')"
                        min="0"
                        :value="campaignAmount || this.currentCampaign.campaignAmount"
                        @input="campaignAmount = $event.target.value"
                        pattern="[0-9]*"
                      />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
                <li class="item-content item-input item-input-outline">
                  <div class="item-media">
                    {{ association.currencyCode }}
                  </div>
                  <div class="item-inner">
                    <div class="item-title item-label">
                      {{ $t("associationedit.fundingTargetoptional") }}
                    </div>
                    <div class="item-input-wrap">
                      <input
                        type="number"
                        name="fundingTarget"
                        :placeholder="$t('associationedit.fundingTargetoptional')"
                        min="0"
                        :value="fundingTarget || this.currentCampaign.fundingTarget"
                        @input="fundingTarget = $event.target.value"
                        pattern="[0-9]*"
                      />

                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
                <f7-list-input
                  :label="
                    $t('associationedit.selectCampaignprojectManager', {
                      campaignType: this.getCampaignType.toLowerCase(),
                    })
                  "
                  type="select"
                  :placeholder="
                    $t('associationedit.selectCampaignprojectManager', {
                      campaignType: this.getCampaignType.toLowerCase(),
                    })
                  "
                  :defaultValue="this.currentCampaign.campaignManager"
                  @input="campaignManager = $event.target.value"
                  outline
                >
                  <option
                    v-for="member in this.association.associationMemberList"
                    :key="member.id"
                    :value="member.id"
                  >
                    {{ member.name }}
                  </option>
                </f7-list-input>
                <f7-block-title class="item-header">{{
                  $t("associationedit.campaignprojectDescription", {
                    campaignType: this.getCampaignType,
                  })
                }}</f7-block-title>
                <f7-text-editor
                  :value="description || this.currentCampaign.description"
                  name="description"
                  @texteditor:change="(v) => (description = v)"
                  outline
                  resizable
                  required
                  validate
                  type="texteditor"
                  :style="
                    f7.device.cordova || !this.isTablet
                      ? '--f7-text-editor-height: 150px'
                      : ''
                  "
                />
              </f7-list-group>
            </f7-list>
            <f7-toolbar
              tabbar
              bottom
              no-hairline
              bg-color="red"
              :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
            >
              <f7-link
                raised
                fill
                large
                class="link buttons"
                text-color="white"
                strong
                @click="updateCampaign()"
                >{{
                  $t("associationedit.updateCampaignproject", {
                    campaignType: this.getCampaignType.toLowerCase(),
                  })
                }}</f7-link
              >
            </f7-toolbar>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-sheet>
  </f7-page>
</template>
<style scoped>
.calendar-day.calendar-day-prev {
  pointer-events: none;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7AccordionContent,
  f7BlockTitle,
  f7ListInput,
  f7TextEditor,
  f7Sheet,
  f7Toolbar,
  f7PageContent,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import { attachDocs } from "../js/mixins/attachDocs.js";

import { association } from "../js/mixins/association.js";
import membersComponent from "../components/modules/members.vue";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    membersComponent,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7AccordionContent,
    f7BlockTitle,
    f7ListInput,
    f7TextEditor,
    f7Sheet,
    f7Toolbar,
    f7PageContent,
  },
  mixins: [association, attachDocs],
  data() {
    return {
      theme,
      f7,
      frequencyType: "",
      projectCategoryId: "",
      name: "",
      frequency: "",
      paymentCurrency: "",
      tag: "",
      description: "",
      associationTitle: "",
      forms: "",
      files: "",
      countryCode: "",
      campaignTitle: "",
      campaignDescription: "",
      fundingTarget: "",
      campaignAmount: "",
      startDate: "",
      endDate: "",
      campaignManager: "",
      currentCampaign: "",
      type: "association",
      hiddenFrom: [],
      campaignBeneficiary: "",
      fileToSave: [],
    };
  },
  mounted() {
    if (!this.association) {
      this.$store.dispatch("asso/getAssociation", this.f7route.params.associationId);
    }
    this.tag = this.association.tag;
    this.description = this.association.description;
    this.name = this.association.name;
    this.hideOrShowcampaign = this.$t("associationdetail.hideCampaignFromBeneficiary");
    if (this.currentCampaign) {
      this.endDate = this.currentCampaign.endDate;
    }
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    this.paymentCurrency = this.association.currencyCode;
    if (this.association === "") {
      this.$store.dispatch(
        "asso/getAssociation",
        this.association.id || this.f7route.params.associationId
      );
    }

    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
  },
  methods: {
    async updateAssociation() {
      if (!f7.input.validateInputs("#createNewassociation")) {
        return;
      }
      f7.preloader.show("multi");
      const newAssociation = {
        id: this.association.id,
        description: this.description,
        tag: this.tag,
        name: this.name,
      };
      this.forms = new FormData();
      await this.forms.append("association", JSON.stringify(newAssociation));
      for (const element of this.documentsUrl) {
        this.forms.append("files", element.file);
      }
      await this.$store.dispatch("asso/editAssociation", this.forms);
      await this.$store.dispatch("auth/clearDocumentsUrl");
      await this.$store.dispatch("asso/getAssociation", this.association.id);
      setTimeout(() => {
        this.f7router.navigate(`/association/${this.association.id}`);
        f7.preloader.hide();
      }, 300);
    },
    selectCampaign(campaign) {
      this.currentCampaign = campaign;
      if (this.association.type === "group") {
        this.hiddenFrom = campaign.hiddenFrom;
        this.campaignBeneficiary = campaign.campaignBeneficiary;
      }
      f7.sheet.open(".updateCampaign");
    },
    async updateCampaign() {
      if (!f7.input.validateInputs("#updateCampaign")) {
        return;
      }
      f7.preloader.show("multi");
      const newCampaign = {
        id: this.currentCampaign.id,
        name: this.name || this.currentCampaign.name,
        description: this.description || this.currentCampaign.description,
        endDate: this.endDate[0] || this.currentCampaign.endDate,
        campaignAmount: this.campaignAmount || this.currentCampaign.campaignAmount,
        fundingTarget: this.fundingTarget || this.currentCampaign.fundingTarget,
        campaignManager: this.campaignManager || this.currentCampaign.campaignManager,
        campaignBeneficiary:
          this.campaignBeneficiary || this.currentCampaign.campaignBeneficiary,
        hiddenFrom:
          this.association.type === "group"
            ? this.hiddenFrom || this.currentCampaign.hiddenFrom
            : [],
      };
      await this.$store.dispatch("asso/editCampaign", newCampaign);
      await this.$store.dispatch("asso/getAssociation", this.association.id);
      setTimeout(() => {
        f7.sheet.close(".updateCampaign");
        f7.preloader.hide();
      }, 300);
    },
    beforeOut() {
      const self = this;
      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("asso", [
      "association",
      "campaign",
      "member",
      "associationList",
      "memberList",
      "associationTransaction",
      "associationTransactionList",
    ]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
      "document",
    ]),

    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),

    daysLeft() {
      return this.endDate
        ? (
            (new Date(this.endDate).getTime() - new Date().getTime()) /
            (1000 * 3600 * 24)
          ).toFixed(0) + this.$t("common.inDay")
        : (
            (new Date(this.currentCampaign.endDate).getTime() - new Date().getTime()) /
            (1000 * 3600 * 24)
          ).toFixed(0) + this.$t("common.inDay");
    },

    currentDocuments() {
      const associationPhotoImage = this.association.assoImageList;
      const logo = {
        id: this.association.id,
        name: "logo",
        filetype: "image/png",
        image: this.association.logo,
        url: `data:image/png;base64,${this.association.logo}`,
      };
      if (!!associationPhotoImage && !!this.association.logo) {
        associationPhotoImage.push(logo);
      }
      console.log(associationPhotoImage);
      return associationPhotoImage;
    },
  },
};
</script>
