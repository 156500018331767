import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "crowd";
import { f7 } from "framework7-vue";

// const wallet = {
export default (app) => {
  const projectCat = app.config.globalProperties.$lcStorage.get("cc4a03754c7a");
  return {
    namespaced: true,
    state: {
      wallet: "",
      beneficiaryWallet: "",
      projectCategories: projectCat ? JSON.parse(projectCat) : "",
      walletOwner: "",
      privateWalletList: "",
      publicWalletList: "",
      walletList: "",
      publicCategoryWalletList: "",
      thirdPartyAllowedWalletList: "",
      walletTransaction: "",
      privateWalletTransactionList: "",
      publicWalletTransactionList: "",
      walletTransactionList: "",
      walletImages: "",
      id: "",
      walletQRImage: "",
      walleTtransaction: "",
    },
    getters: {},
    mutations: {
      CREATE_WALLET(state, wallet) {
        state.wallet = wallet;
      },
      GET_WALLET(state, wallet) {
        state.wallet = wallet;
      },
      GET_BENEFOCIARY_WALLET(state, beneficiaryWallet) {
        state.beneficiaryWallet = beneficiaryWallet;
      },
      GET_WALLETIMAGES(state, walletImages) {
        state.walletImages = walletImages;
      },
      GET_WALLETCATEGORY(state, projectCategories) {
        state.projectCategories = projectCategories;
        app.config.globalProperties.$lcStorage.set(
          "cc4a03754c7a",
          JSON.stringify(state.projectCategories)
        );
      },
      EDIT_WALLET(state, wallet) {
        state.wallet = wallet;
      },
      GET_WALLETLIST(state, walletList) {
        state.walletList = walletList;
      },
      GET_PRIVATE_WALLETLIST(state, privateWalletList) {
        state.privateWalletList = privateWalletList;
      },
      GET_PUBLIC_WALLETLIST(state, publicWalletList) {
        state.publicWalletList = publicWalletList;
      },

      GET_PUBLICCATEGORYWALLETLIST(state, publicCategoryWalletList) {
        state.publicCategoryWalletList = publicCategoryWalletList;
      },
      GET_ACCOUNTIMAGE(state, accountImages) {
        state.accountImages = accountImages;
      },
      DELETE_ACCOUNTIMAGE(state, payload) {
        state.id = payload;
      },
      GET_WALLETOWNER(state, walletOwner) {
        state.walletOwner = walletOwner;
      },
      REMOVE_WALLETOWNER(state, payload) {
        state.id = payload;
      },
      GET_WALLET_TRANSACTION(state, walletTransaction) {
        state.walletTransaction = walletTransaction;
      },
      GET_WALLETTRANSACTION_LIST(state, walletTransactionList) {
        state.walletTransactionList = walletTransactionList;
      },
      GET_WALLET3RDPARTYALLOWEDWALLETLIST(state, thirdPartyAllowedWalletList) {
        state.thirdPartyAllowedWalletList = thirdPartyAllowedWalletList;
      },
      GET_PRIVATEWALLETTRANSACTIONLIST(state, privateWalletTransactionList) {
        state.privateWalletTransactionList = privateWalletTransactionList;
      },
      GET_PUBLICWALLETTRANSACTIONLIST(state, publicWalletTransactionList) {
        state.publicWalletTransactionList = publicWalletTransactionList;
      },
      CREATE_QRCODE(state, payload) {
        state.walletQRImage = payload;
      },
      READ_QRCODE(state, payload) {
        state.wallet = payload;
      },
      TOP_UP_WALLET(state, walletTransaction) {
        state.walletTransaction = walletTransaction;
      },
    },

    actions: {
      createWallet({ commit, dispatch }, payload) {
        axios({
          url: path,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((createdWallet) => {
            console.log(createdWallet);
            commit("GET_WALLET", createdWallet);
            f7.views.main.router.navigate(`/wallet/detail/${createdWallet.id}`);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getCreatedWallet({ commit }, payload) {
        axios({
          url: path,
          method: "GET",
          params: { projectWalletId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editWallet({ commit }, payload) {
        axios({
          url: path,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      createCampaign({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/campaign`,
          method: "POST",
          data: payload,
          // params: {
          //   projectWalletId: payload.projectWalletId,
          // },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editCampaign({ commit }, payload) {
        axios({
          url: `${path}/campaign`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      addCampaignManager({ commit }, payload) {
        axios({
          url: `${path}/manager`,
          method: "PUT",
          params: {
            userId: payload.userId,
            campaignid: payload.campaignId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      updateBeneficiary({ commit }, payload) {
        axios({
          url: `${path}/beneficiary`,
          method: "PUT",
          data: payload,
          params: {
            userId: payload.userId,
            beneficiaryWalletId: payload.beneficiaryWalletId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getPublicWalletById({ commit }, payload) {
        axios({
          url: `${path}/public/one`,
          params: { projectWalletId: payload },
          method: "GET",
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getPrivateWalletById({ commit }, payload) {
        axios({
          url: `${path}/user`,
          method: "GET",
          params: { projectWalletId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getWallet({ commit }, payload) {
        if (app.config.globalProperties.$keycloak.token) {
          axios({
            url: path,
            method: "GET",
            params: { projectWalletId: payload },
            headers: {
              Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            },
          })
            .then((r) => r.data)
            .then((wallet) => {
              console.log(wallet);
              commit("GET_WALLET", wallet);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios({
            url: `${path}/public/one`,
            params: { projectWalletId: payload },
            method: "GET",
          })
            .then((r) => r.data)
            .then((wallet) => {
              console.log(wallet);
              commit("GET_WALLET", wallet);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      getBeneficiaryWallet({ commit }, payload) {
        axios({
          url: `${path}/get/${payload}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((beneficiaryWallet) => {
            commit("GET_BENEFOCIARY_WALLET", beneficiaryWallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getWalletImagesById({ commit }, payload) {
        axios({
          url: `${path}/images`,
          method: "GET",
          params: { projectWalletId: payload },
        })
          .then((r) => r.data)
          .then((walletImages) => {
            commit("GET_WALLETIMAGES", walletImages);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getPrivateWalletList({ commit }, payload) {
        axios({
          url: `${path}/user/list`,
          method: "GET",
          // params: {
          //   maxNumberOfItem: payload,
          // },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((privateWalletList) => {
            commit("GET_PRIVATE_WALLETLIST", privateWalletList);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getPublicWalletList({ commit }, payload) {
        axios({
          url: `${path}/public`,
          params: {
            maxNumberOfItem: payload,
          },
          method: "GET",
        })
          .then((r) => r.data)
          .then((publicWalletList) => {
            console.log("publicWalletList", publicWalletList);
            commit("GET_PUBLIC_WALLETLIST", publicWalletList);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getThirdPartyAllowedWallets({ commit }, payload) {
        axios({
          url: `${path}/third`,
          method: "GET",
          params: {
            // maxNumberOfItem: payload.maxNumberOfItem,
            countryCode: payload,
          },
        })
          .then((r) => r.data)
          .then((thirdPartyAllowedWalletList) => {
            commit(
              "GET_WALLET3RDPARTYALLOWEDWALLETLIST",
              thirdPartyAllowedWalletList
            );
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editAccountImages({ commit }, payload) {
        axios({
          url: `${path}/image`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      deleteImages({ commit }, payload) {
        axios({
          url: `${path}/image`,
          method: "DELETE",
          params: {
            imageId: payload.imageId,
            walletId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      addNewWalletOwner({ commit }, payload) {
        axios({
          url: `${path}/owner`,
          method: "POST",
          params: {
            userId: payload.userId,
            walletId: payload.walletId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      removeWalletOwner({ commit }, payload) {
        axios({
          url: `${path}/owner`,
          method: "PUT",
          params: {
            userId: payload.userId,
            walletId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      grantAdminRole({ commit }, payload) {
        axios({
          url: `${path}/admin`,
          method: "POST",
          params: {
            userId: payload.userId,
            walletId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      withdrawAdminRole({ commit }, payload) {
        axios({
          url: `${path}/admin`,
          method: "PUT",
          params: {
            userId: payload.userId,
            walletId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err.message);
          });
      },

      changeWalletPublicStatus({ commit }, payload) {
        axios({
          url: `${path}/status`,
          method: "PUT",
          params: {
            status: payload.status,
            projectWalletId: payload.walletId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("GET_WALLET", wallet);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getWalletCategory({ commit }) {
        axios({
          url: `${path}/getCategory`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((projectCategories) => {
            commit("GET_WALLETCATEGORY", projectCategories);
            console.log(projectCategories);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      redeemWallet({ commit, rootState }, payload) {
        axios({
          url: `${path}/transactions/redeem-wallet`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.sub,
            redeemedAmount: payload.redeemedAmount,
            walletId: payload.walletId,
            beneficiaryAccountNumber: payload.beneficiaryAccountNumber,
            beneficiaryUserId: payload.beneficiaryUserId,
            payerComment: payload.payerComment,
            id: payload.id,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((walletTransaction) => {
            commit("GET_WALLET_TRANSACTION", walletTransaction);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      validateWalletRedeem({ commit }, payload) {
        axios({
          url: `${path}/redeem/validate`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.sub,
            transferCode: payload.transferCode,
            transferPIN: payload.transferPIN,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((walletTransaction) => {
            commit("GET_WALLET_TRANSACTION", walletTransaction);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      readQRcode({ commit }, payload) {
        axios({
          url: `${path}/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("READ_QRCODE", wallet);
            console.log(wallet);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      readPublicQRcode({ commit }, payload) {
        axios({
          url: `${path}/public/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
        })
          .then((r) => r.data)
          .then((wallet) => {
            commit("READ_QRCODE", wallet);
            console.log(wallet);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
    },
  };
};
// export default wallet;
