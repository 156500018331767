<template>
  <f7-page class="selectPaymentMethod" :hide-toolbar-on-scroll="false">
    <f7-navbar
      sliding
      no-shadow
      :back-link="$t('common.backButton')"
      :back-link-show-text="false"
    >
      <f7-nav-title sliding>{{ $t("common.proceedPayment") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout block">
        <f7-list dividers-ios strong-ios media-list>
          <f7-list-group v-if="this.transferDetail.amount > 0.01">
            <f7-card outline header-divider raised class="no-margin">
              <f7-card-header>{{ $t("common.paymentDetails") }}</f7-card-header>
              <f7-list-item
                :header="$t('common.amount')"
                :title="
                  $filtersToCurrency.toCurrency(
                    this.transferDetail.amount,
                    null,
                    this.transferDetail.currency
                  )
                "
              />
              <f7-list-item :header="$t('common.tag')" :title="this.transferDetail.tag" />
              <f7-list-item
                :header="$t('common.description')"
                :title="this.transferDetail.description"
              />
            </f7-card>
          </f7-list-group>
          <f7-list-group v-else class="noPaymentDetails" form id="noPaymentDetails">
            <f7-block-title medium>{{
              $t("common.fillInPaymentDetails")
            }}</f7-block-title>
            <f7-list-input
              :label="$t('common.amount')"
              floating-label
              :placeholder="
                this.payment_detail && this.payment_detail.setAmount != 0
                  ? this.payment_detail.setAmount
                  : $t('common.enterAmount')
              "
              min="0"
              pattern="[0-9]*"
              :info="$t('common.enterAmount')"
              :error-message="$t('common.enterAmount')"
              v-model:value="this.amount"
              type="number"
              name="amount"
              class="inputNumber"
              tabindex="1"
              validate
              required
              clear-button
              ><template #media>
                {{ this.payment_detail.paymentCurrency }}</template
              ></f7-list-input
            >
            <f7-list-input
              floating-label
              :label="$t('common.tag')"
              :info="$t('common.tagInfo')"
              :error-message="$t('common.tagInfo')"
              v-model:value="this.tag"
              name="tag"
              maxlength="100"
              validate
              required
              tabindex="2"
              type="text"
              clear-button
              resizable
            />
            <f7-list-input
              floating-label
              :label="$t('common.description')"
              :info="$t('common.transferDescInfo')"
              :error-message="$t('common.descriptionInfo')"
              v-model:value="this.description"
              name="description"
              validate
              required
              tabindex="3"
              type="text"
              clear-button
            ></f7-list-input>
          </f7-list-group>

          <!-- TODO insert code to display payment methods for destination country (card, mobile, avocash) -->

          <!-- <f7-list dividers-ios media-list strong-ios>
            <f7-list-group>
              <div class="grid grid-cols-1 medium-grid-cols-2" v-if="this.selected_method === ''">
                <f7-list-item v-for="(item, index) in this.userPaymentMethod" :key="index" radio :value="item.method"
                  @click="selectMethod(item.method)" @change="(e) => {
                      if (e.target.checked) this.selected_method = item.method;
                    }
                    " radio-icon="start" :title="item.name" name="payment_method"><template #media><img
                      class="margin-left-half" v-if="item.img !== ''" :src="item.img" /><span class="color-primary" v-else
                      v-html="item.icon"></span></template>
                </f7-list-item>
              </div>
              <f7-list-item v-if="this.selected_method !== ''" link="#" :header="$t('common.selectedpaymentmethod')"
                :title="this.selectedPaymentMetod.name" @click="resetMethod()">
                <template #media><img v-if="this.selectedPaymentMetod.img !== ''" class="margin-lefthalf"
                    :src="this.selectedPaymentMetod.img" /><span class="color-primary" v-else
                    v-html="this.selectedPaymentMetod.icon"></span></template>
                <template #after><f7-icon ios="f7:multiply_circle_fill" md="material:cancel"
                    color="red" /></template></f7-list-item>
            </f7-list-group>
          </f7-list>
        </span> -->

          <f7-list-group
            v-if="this.transferDetail.amount > 1 && this.userPaymentProvider === 'bizao'"
          >
            <f7-block-title medium>{{
              $t("common.selectpaymentmethide")
            }}</f7-block-title>
            <div :class="this.network_sec.length > 0 ? 'network-grid' : ''">
              <span class="main-item-swiper">
                <swiper-container :slidesPerView="this.network_main.length">
                  <swiper-slide
                    class
                    v-for="(network, index) in this.network_main"
                    :key="index"
                    :style="{
                      width: isTablet ? 568 / 5 + 'px' : f7.width / 4 + 'px',
                      cursor: 'pointer',
                    }"
                    @click="selectPaymentMethod(network, network.mthd)"
                  >
                    <f7-card
                      raised
                      outline
                      bg-color="white"
                      :id="`${network.id}`"
                      :padding="false"
                      class="margin-half"
                      :style="{
                        'border-radius': '5px',
                      }"
                    >
                      <f7-card-content
                        :padding="false"
                        class="padding-half grid grid-rows-2"
                        style="max-height: 40px"
                      >
                        <i
                          class="icon no-margin no-padding"
                          v-if="network.img !== ''"
                          style="height: 30px"
                        >
                          <img :src="network.img" class="icon" width="24"
                        /></i>
                        <span
                          class="icon no-margin no-padding"
                          v-else
                          v-html="network.icon"
                        ></span>
                        <h5
                          v-if="network.mthd !== 'mobilemoney'"
                          class="no-margin no-padding-bottom padding-top-half"
                          style="white-space: nowrap; text-overflow: ellipsis"
                        >
                          {{ $t(network.name) }}
                        </h5>
                        <h5
                          v-else
                          class="no-margin no-padding-bottom padding-top-half"
                          style="white-space: nowrap; text-overflow: ellipsis"
                          v-html="isTablet ? network.name : network.name_short"
                        ></h5>
                      </f7-card-content>
                    </f7-card>
                  </swiper-slide> </swiper-container
              ></span>
              <f7-card
                raised
                outline
                bg-color="white"
                @click="openExtraNetwork()"
                style="border-radius: 5px; cursor: pointer"
                :padding="false"
                class="margin-half"
                v-if="this.network_sec.length > 0"
              >
                <f7-card-content
                  :padding="false"
                  class="padding-half grid grid-rows-2"
                  style="max-height: 40px"
                >
                  <swiper-container
                    :autoplay="true"
                    :loop="true"
                    :effect="'slide'"
                    :speed="5000"
                    direction="vertical"
                    :slides-per-view="1"
                    style="max-width: 40px"
                  >
                    <swiper-slide v-for="network in this.network_sec" style="width: 20px">
                      <i class="icon" v-if="network.img !== ''" style="height: 30px">
                        <img :src="network.img" class="icon" width="18"
                      /></i>
                      <span
                        width="18"
                        class="icon no-margin no-padding"
                        v-else
                        v-html="network.icon"
                      ></span
                    ></swiper-slide>
                  </swiper-container>
                  <f7-icon
                    f7="chevron_down"
                    size="20px"
                    class="margin-top-half extraNetworkListSelect"
                  ></f7-icon>
                </f7-card-content>
              </f7-card>
            </div>

            <f7-block
              strong
              class="padding-half"
              v-if="this.selectedMthd.id !== 'avocash' && this.selectedMthd !== ''"
            >
              <f7-list-item
                :title="
                  this.selectedMthd.mthd === 'mobilemoney'
                    ? this.selectedMthd.name
                    : $t(this.selectedMthd.name)
                "
              >
                <template #media>
                  <img
                    v-if="this.selectedMthd.img !== ''"
                    :src="this.selectedMthd.img"
                    class="icon"
                    style="height: auto"
                  />
                  <span
                    v-if="this.selectedMthd.icon !== ''"
                    v-html="this.selectedMthd.icon"
                  ></span>
                </template>
              </f7-list-item>
              <f7-list-item :text="$t('common.redirectToPaymentUrl')">
                <template #media>
                  <f7-icon
                    ios="f7:square_arrow_right"
                    md="material:open_in_new"
                  /> </template
              ></f7-list-item>
            </f7-block>
            <!-- </f7-tab>
            </f7-tabs> -->
          </f7-list-group>
          <!-- <span v-if="this.selected_method === 'mobilemoney' && mobile_money_network.length > 0
              ">
              <f7-list-item :title="$t('common.selectMobilemoneyNetwork')" />

              <span class="grid no-gap grid-cols-4">
                <f7-ca[rd v-for="(network, index) in this.mobile_money_network" :key="index" radio :value="network.id"
                  @click="selectMobileNetWork(network.id)" @change="(e) => {
                      if (e.target.checked) this.mnoName = network.id;
                    }
                    " radio-icon="start" name="selected_mobile_network" :data-option-color="`${network.color}`"
                  :class="`color-${network.color}`" :style="`text-color:${network.color}`">
                  <f7-card-content><img :src="network.img"
                      style="height: auto; border: none" /></f7-card-content><template #media><img :src="network.img"
                      style="height: auto; border: none" /></template>
                  <f7-card-footer>{{ network.name }}</f7-card-footer>
                </f7-ca>
              </span>
            </span> -->
          <f7-list-group>
            <div
              class="otp_block grid grid-cols-1"
              v-if="this.selected_method === 'mobilemoney' && this.mnoName !== 'wave'"
            >
              <f7-list-item>
                <f7-toggle
                  class="use-otp"
                  name="use-otp"
                  v-model:value="this.channel"
                  @toggle:change="changeChannel()"
                  color="primary"
                ></f7-toggle>
                <span class="margin-left-half item-footer">
                  {{ $t("common.use_otp_code") }}
                </span>
              </f7-list-item>

              <input
                v-if="this.channel !== 'web'"
                type="text"
                inputmode="numeric"
                name="code"
                :value="this.otp_code"
                id="demo-numpad-inline"
                style="
                  font-size: x-large;
                  letter-spacing: 5px;
                  text-align: center;

                  width: auto;
                  height: auto;
                "
                placeholder="______"
                required
                minlength="4"
                maxlength="6"
                size="6"
              />
            </div>
            <f7-list-input
              :label="$t('common.phoneNumber')"
              type="tel"
              name="telephone"
              input-id="phoneNumber"
              v-model:value="this.telephone"
              :error-message="$t('common.providePhoneNumber')"
              required
              :hidden="this.selected_method !== 'mobilemoney' ? true : false"
              validate
            />

            <f7-list-item
              :title="$t('common.selectCountry')"
              class="selectCountry item-label"
              smart-select
              :smart-select-params="{
                openIn: !this.isTablet || this.f7.device.cordova ? 'popup' : 'page',
                searchbar: true,
                searchbarPlaceholder: $t('common.findCountry'),
                closeOnSelect: true,
              }"
              style="display: none"
            >
              <select name="country" v-model="this.selectedCountry">
                <optgroup
                  class="list-group"
                  v-for="(countryList, continentName, index) in this.countries"
                  :label="continentName"
                  :key="index"
                >
                  <option
                    v-for="country in countryList"
                    :key="country.id"
                    :value="country.countryCode2"
                    :selected="country.countryCode2 === selectedCountry"
                    :data-option-image="
                      'https://flagcdn.com/' + country.countryCode2.toLowerCase() + '.svg'
                    "
                    width="24"
                  >
                    {{ country.name }} {{ country.countryCode2 }} +{{ country.dialCode }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>
            <span
              v-if="
                this.payment_detail.transaction_type === 'cash_out' &&
                this.userPaymentProvider === 'bizao'
              "
            >
              <f7-list-input
                :label="$t('common.firstName')"
                floating-label
                type="text"
                name="firstName"
                :placeholder="$t('common.firstName')"
                v-model:value="this.firstName"
                :error-message="$t('common.provideFirstName')"
                required
                validate
                clear-button
              />
              <f7-list-input
                :label="$t('common.lastName')"
                floating-label
                type="text"
                name="lastname"
                :placeholder="$t('common.lastName')"
                v-model:value="this.lastName"
                :error-message="$t('common.provideLastName')"
                required
                validate
                clear-button
              />
              <f7-list-input
                :label="$t('common.address')"
                floating-label
                type="text"
                name="address"
                :placeholder="$t('common.address')"
                v-model:value="this.address"
                :error-message="$t('common.provideAddress')"
                required
                validate
                clear-button
              />
            </span>
          </f7-list-group>
          <f7-list-group
            v-if="
              this.transferDetail.amount > 1 &&
              this.payment_detail.transaction_type === 'cash_in'
            "
          >
            <span v-if="this.selected_method === 'avocash'">
              <f7-list-item
                class="selectApiWalet"
                valueEl="item-subtitle"
                :title="$t('common.selectAccount')"
                smart-select
                :smart-select-params="{
                  openIn: isTablet ? 'popup' : 'sheet',
                  sheetSwipeToClose: true,
                  closeOnSelect: true,
                }"
              >
                <select name="receiver_wallet_id" v-model="this.sender_wallet_id">
                  <optgroup
                    v-for="(apiWalletList, accountType, index) in this.userAccount
                      .userAccountWallets"
                    :key="index"
                    :label="getAccountType(accountType)"
                  >
                    <option
                      v-for="(actWallet, index) in apiWalletList.filter(
                        (x) => !x.shared && x.id !== baseTransfer.receiver_wallet_id
                      )"
                      :key="index"
                      :value="actWallet.id"
                      :data-option-color="actWallet.main ? 'primary' : ''"
                      :selected="actWallet.id === baseTransfer.sender_wallet_id"
                      :disabled="
                        actWallet.balance < this.baseTransfer.amount ||
                        actWallet.balance < this.amount
                      "
                    >
                      {{ actWallet.id + " " + getWalletTag(actWallet) }}
                    </option>
                  </optgroup>
                </select>
              </f7-list-item>
            </span>
          </f7-list-group>
          <f7-list-group
            v-if="
              this.transferDetail.amount > 1 &&
              this.payment_detail.transaction_type === 'cash_out'
            "
          >
            <span v-if="this.selected_method === 'avocash'"> </span>
            <span v-if="this.selected_method === 'bizao'"> </span>
            <span v-if="this.selected_method === 'banktransfer'">
              <span
                v-if="
                  (this.userAccount.userBankAccounts.STANDARD &&
                    this.userAccount.userBankAccounts.STANDARD.length > 0) ||
                  (this.userAccount.userBankAccounts.BUSINESS &&
                    this.userAccount.userBankAccounts.BUSINESS.length > 0)
                "
              >
                <f7-list-item :title="$t('bankAccount.mybankaccounts')" />
                <span
                  v-for="(accountsList, account_type, index) in this.userAccount
                    .userBankAccounts"
                  :key="index"
                >
                  <f7-list-item
                    group-title
                    :title="getAccountType(account_type)"
                    v-if="accountsList.length > 0"
                  />
                  <f7-list-item
                    v-if="accountsList.length > 0"
                    v-for="(account, index) in accountsList"
                    :key="index"
                    radio
                    :value="account.id"
                    :disabled="account.status !== 'ACTIVE'"
                    @click="selectMethod(account.id)"
                    @change="
                      (e) => {
                        if (e.target.checked) this.selected_bank_account = account.id;
                      }
                    "
                    radio-icon="start"
                    :title="account.tag"
                    :subtitle="$t('bankAccount.number') + ': ' + account.number"
                    :text="
                      account.bic !== null
                        ? $t('bankAccount.bic') + ': ' + account.bic
                        : ''
                    "
                    name="selected_CreditCardId"
                    :checked="account.main"
                    :class="account.main ? 'border-color-primary highlighted_horiz' : ''"
                  >
                  </f7-list-item>
                </span>
              </span>
            </span>
            <span v-if="this.selected_method === 'creditcard'">
              <span
                v-if="
                  (this.userAccount.userCreditCards.STANDARD &&
                    this.userAccount.userCreditCards.STANDARD.length > 0) ||
                  (this.userAccount.userCreditCards.BUSINESS &&
                    this.userAccount.userCreditCards.BUSINESS.length > 0)
                "
              >
                <f7-list-item :title="$t('bankCard.paywithregisteredcard')" />
                <span
                  v-for="(creditCardsList, account_type, index) in this.userAccount
                    .userCreditCards"
                  :key="index"
                >
                  <f7-list-item
                    group-title
                    :title="getAccountType(account_type)"
                    v-if="creditCardsList.length > 0"
                  />
                  <f7-list-item
                    v-if="creditCardsList.length > 0"
                    v-for="(creditcard, index) in creditCardsList"
                    :key="index"
                    radio
                    :value="creditcard.id"
                    :disabled="creditcard.status !== 'ACTIVE'"
                    @click="selectMethod(creditcard.id)"
                    @change="
                      (e) => {
                        if (e.target.checked) this.selected_CreditCardId = creditcard.id;
                      }
                    "
                    radio-icon="start"
                    :title="creditcard.number"
                    :subtitle="creditcard.tag"
                    :header="$t('common.cardNumber')"
                    :after="`${isTablet ? $t('common.expiryDate') + ':' : ''} ${
                      creditcard.expiry_date
                    }`"
                    name="selected_CreditCardId"
                    :class="`text-color-${
                      cardNetWork.find((cd) => cd.value === creditcard.brand).color
                    }`"
                  >
                    <template #media
                      ><img
                        :src="
                          cardNetWork.find((cd) => cd.value === creditcard.brand).img
                        "
                    /></template> </f7-list-item
                  >_met
                </span>
              </span>
            </span>
          </f7-list-group>

          <!-- <span v-if="this.selected_method === 'creditcard'">
                <span v-if="(this.userAccount.userCreditCards.STANDARD &&
                    this.userAccount.userCreditCards.STANDARD.length > 0) ||
                  (this.userAccount.userCreditCards.BUSINESS &&
                    this.userAccount.userCreditCards.BUSINESS.length > 0)
                  ">
                  <f7-list-item :title="$t('bankCard.paywithregisteredcard')" />
                  <span v-for="(creditCardsList, account_type, index) in this.userAccount
                    .userCreditCards" :key="index">
                    <f7-list-item group-title :title="getAccountType(account_type)" v-if="creditCardsList.length > 0" />
                    <f7-list-item v-if="creditCardsList.length > 0" v-for="(creditcard, index) in creditCardsList"
                      :key="index" radio :value="creditcard.id" :disabled="creditcard.status !== 'ACTIVE'"
                      @click="selectMethod(creditcard.id)" @change="(e) => {
                          if (e.target.checked)
                            this.selected_CreditCardId = creditcard.id;
                        }
                        " radio-icon="start" :title="creditcard.number" :subtitle="creditcard.tag"
                      :header="$t('common.cardNumber')" :after="`${isTablet ? $t('common.expiryDate') + ':' : ''} ${creditcard.expiry_date
                        }`" name="selected_CreditCardId" :class="`text-color-${cardNetWork.find((cd) => cd.value === creditcard.brand).color
    }`"><template #media><img :src="cardNetWork.find((cd) => cd.value === creditcard.brand).img
    " /></template>
                    </f7-list-item>
                  </span>
                </span>
                <f7-list-item group-title>{{ $t("common.newCard") }} </f7-list-item>
                <f7-list-item radio radio-icon="start" @click="selectMethod('newCard')" @change="(e) => {
                    if (e.target.checked) this.selected_CreditCardId = 'newCard';
                  }
                  " name="selected_CreditCardId" value="newCard" :title="$t('bankCard.paywithnewcard')" />
              </span> -->
          <!-- <f7-button raised fill large :class="!isTablet ? 'margin' : 'margin-vertical'" color="red"
                @click="makeContribution()" v-if="this.selected_method !== '' ||
                  (this.selected_method === 'mobilemoney' && this.telephone !== '') ||
                  (this.selected_method === 'avocash' && this.sender_wallet_id !== '') ||
                  (this.selected_method === 'creditcard' &&
                    this.selected_CreditCardId !== '')
                  ">{{ $t("common.contribute") }}</f7-button> -->
        </f7-list>
        <f7-toolbar
          v-if="
            this.selected_method !== '' ||
            (this.selected_method === 'mobilemoney' && this.telephone !== '') ||
            (this.selected_method === 'avocash' && this.sender_wallet_id !== '') ||
            (this.selected_method === 'creditcard' &&
              this.selected_CreditCardId !== '') ||
            (this.selected_method === 'bankaccount' &&
              this.selected_bank_account_id !== '' &&
              this.detail_confirmed == true)
          "
          tabbar
          bottom
          bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
        >
          <f7-link
            raised
            fill
            large
            class="link buttons"
            ripple
            @click="makeContribution()"
            strong
            text-color="white"
            >{{ $t("common.contribute") }}</f7-link
          >
        </f7-toolbar>
        <f7-toolbar
          v-if="this.detail_confirmed == false"
          tabbar
          bottom
          bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
        >
          <f7-link
            raised
            fill
            large
            class="link buttons"
            ripple
            @click="preConfirm()"
            strong
            text-color="white"
            >{{ $t("common.confirmDetail") }}</f7-link
          >
        </f7-toolbar>
        <stripCheckOut
          ref="stripeComponent"
          :stripeAccountId="this.stripeAccountId"
          :payment_options="this.payment_options"
          :detail_confirmed="this.detail_confirmed"
          v-if="this.userPaymentProvider === 'stripe'"
        >
        </stripCheckOut>
      </div>
      <div class="right_layout"></div>
    </div>

    <f7-popup
      class="authLoginScreen"
      :close-by-backdrop-click="false"
      :close-by-outside-click="false"
      tablet-fullscreen
      swipe-to-close
      :backdrop="false"
      style="min-height: 100%; --f7-popup-bg-color: #fff"
    >
      <f7-page>
        <div class="layout_content3">
          <div class="left_layout3"></div>
          <div class="main_layout3">
            <f7-login-screen-title>{{
              $t("common.authenticateWithPIN")
            }}</f7-login-screen-title>
            <f7-block-header class="text-align-center"
              >{{ $t("common.whereToFindSecretPIN") }}<br /><f7-link
                popup-close
                class="link"
                href="/user/profile"
                >{{ this.profile.name }}</f7-link
              ></f7-block-header
            >
            <form>
              <div class="block passcode-input">
                <input type="text" value="____" id="auth-numpad-inline" />
              </div>
              <div strong class="no-padding no-margin auth-passcode-numpad">
                <div id="auth-inline-container"></div>
              </div>
            </form>
          </div>
          <div class="right_layout3"></div>
        </div>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<style scoped>
.toolbar.tabbar.tabbar-icons {
  background-color: transparent;
}

.otp_block {
  display: grid;
  grid-template-columns: 65% 35%;
}

.paymentMethodsLg {
  border-color: var(--f7-theme-color);
  border-radius: 32px;
  border-width: medium;
}

.paymentMethodsLg {
  width: auto;
  /* height: 50px; */
  padding: 8px;
  border-radius: 0px;
}

.paymentMethodsLg:hover {
  cursor: pointer;
}

.paymentMethodsSm {
  /* height: 50px; */
  width: 100%;
  /* height: 89px; */
  padding: 16px;
  border-radius: 0px;
}

.paymentMethodsLg img {
  height: 25px;
  width: 25%;
  width: fit-content;
}

.pmtCard_name {
  font-size: 10px;
  /* margin-top: 16px; */
  word-wrap: break-word;
  transform: translateX(50%);
}

.pmtCard_img {
  width: 32px;
  transform: translateX(50%);
}

.selected {
  border: 1px solid #0009ff;
  color: #0009ff;
  /* padding: 8px; */
  /* border-radius: 0px; */
}

@media screen and (max-width: 568px) {
  .network-grid {
    display: grid;
    grid-template-columns: 83% 17%;
  }
}

@media screen and (min-width: 569px) {
  .network-grid {
    display: grid;
    grid-template-columns: 90% 10%;
  }
}

.icon_payment_method {
  font-size: 32px;
  color: var(--f7-theme-color);
}

.block.passcode-input {
  position: relative;
  z-index: 5;
}

.auth-passcode-numpad {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  max-width: none;
  z-index: 10;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7BlockTitle,
  f7BlockHeader,
  f7ListItem,
  f7BlockFooter,
  f7Block,
  f7Button,
  f7Card,
  f7CardContent,
  f7Link,
  f7Popup,
  f7Toolbar,
  f7PageContent,
  f7CardFooter,
  f7ListGroup,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import stripCheckOut from "../components/stripeCheckout.vue";
// import transactAuthSheet from "../components/transactAuth.vue";
import { account } from "../js/mixins/account.js";
// import { telephoneUtils } from "../js/mixins/telephone.js";
import { transactAuth } from "../js/mixins/transactAuth.js";
import { finalPayment } from "../js/mixins/payment.js";
import _ from "lodash";
import card_network from "../../public/data/card_network.json";
import payment_network from "../../public/data/mobile_money_network.json";
// import payment_method from "../../public/data/payment_method.js";
export default {
  props: {
    f7route: Object,
    f7router: Object,

    // paymentCurrency: String,
    // project: Object,
    // entity: Object,
    // project_type: String,
    // transaction_type: String,
  },
  components: {
    navbarright: Navbarright,
    stripCheckOut,
    // transactAuthSheet,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7BlockTitle,
    f7BlockHeader,

    f7ListItem,
    f7BlockFooter,
    f7Block,
    f7Button,
    f7Card,
    f7CardContent,
    f7Link,
    f7Popup,
    f7Toolbar,
    f7PageContent,
    f7CardFooter,
    f7ListGroup,
  },
  mixins: [account, /* telephoneUtils, */ transactAuth, finalPayment],
  data() {
    return {
      theme,
      f7,

      // receiver_wallet_id: "",
      sender_wallet_id: "",
      payment_method: [],
      selected_method: "",
      selected_CreditCardId: "",
      selected_bank_account_id: "",
      telephone: "",
      selectedCountry: "",
      countryCode: "",
      itiPhoneNumber: "",
      description: "",
      tag: "",
      amount: 0,
      setAmount: "",
      paymentCurrency: "",
      cardNetWork: card_network,
      payment_network,
      selectedMthd: "",
      payment_detail: "",
      baseTransfer: "",
      firstName: "",
      lastName: "",
      address: "",
      transferDetail: "",
      payment_options: "",
      detail_confirmed: false,
      mnoName: "",
      otp_code: "",
      channel: "web",
      network_main: [],
      network_sec: [],
      selectExtraNetworkList: "",
      numberOfTril: 4,
    };
  },

  mounted() {},
  methods: {},

  computed: {
    ...mapState("account", ["userAccount", "searchedUser", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isTablet",
      "locale",
    ]),
    ...mapState("savings", ["savings", "savingsList"]),
    ...mapState("groupBuy", ["groupBuy", "groupBuyList"]),
    ...mapState("wallet", ["wallet"]),
    ...mapState("tontine", ["tontine"]),
    ...mapState("asso", ["association"]),
    ...mapState("apimoney", [
      // "baseTransfer",
      "cardInResponse",
      "creditCardList",
      "apiWallet",
      "paymentIntent",
    ]),
    ...mapState("location", [
      "country",
      "apiCode",
      "currentUserCountry",
      "emoneyApiList",
    ]),
    ...mapState("stripe", ["publishableKey"]),
    countries() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
