import { f7 } from "framework7-vue";
import { mapState } from "vuex";
import $$ from "dom7";
export const loginFn = {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mounted() {
    const mainToolbar = f7.$(".mainToolbar");
    if (f7.$(".page.withMainToolbar.page-current").length) {
      mainToolbar.css({ display: "block" });
    } else {
      mainToolbar.css({ display: "none" });
    }
    f7.on("page:beforein", (e) => {
      const page = e.detail;
      console.log("page name beforein", page);
    });

    $$(document).on("page:beforein", (e) => {
      const page = e.detail;
      const currentRoute = page.route.path;
      if (
        currentRoute !== null &&
        [
          "/",
          "/home",
          "/fairia/",
          "/dashboard/",
          "/wallets/",
          "/stores/",
        ].includes(currentRoute)
      ) {
        this.selectedTab = page.name;
        mainToolbar.css({ display: "block" });
      } else {
        mainToolbar.css({ display: "none" });
      }
      if (currentRoute !== null && !["/", "/home"].includes(currentRoute)) {
        this.$store.dispatch("auth/setOnboardingFinished", true);
      }
    });
  },
  methods: {
    loginFn() {
      const path =
        f7.views.main.router.currentRoute.path === "/"
          ? "/"
          : f7.views.main.router.currentRoute.path;
      console.log("path ", f7.views.main.router.currentRoute.path);
      const redirect = f7.device.cordova
        ? `${import.meta.env.VITE_AVOCASH_MOBILE_URL_SCHEME}${path}`
        : `${window.location.origin}${path}`;

      this.$keycloak.login({
        redirectUri: redirect,
        locale:
          this.locale === "" ? navigator.language.split("-")[0] : this.locale,
      });
      this.$store.dispatch("auth/setOnboardingFinished", true);
    },
    registerFn() {
      const path =
        f7.views.main.router.currentRoute.path === "/"
          ? "/"
          : f7.views.main.router.currentRoute.path;
      const redirect = f7.device.cordova
        ? `${import.meta.env.VITE_AVOCASH_MOBILE_URL_SCHEME}${path}`
        : `${window.location.origin}${path}`;

      console.log(redirect);
      this.$keycloak.register({
        redirectUri: redirect,
        locale:
          this.locale === "" ? navigator.language.split("-")[0] : this.locale,
      });
      this.$store.dispatch("auth/setOnboardingFinished", true);
    },
    redirectFunction() {
      let link;

      // f7.popup.open(".startPopup");

      f7.preloader.show("multi");

      if (window.location.href.includes("localhost")) {
        link = window.location.href.split(":5173").pop().split("?")[0];
        console.log("link ", link);
      } else if (
        f7.views.main.router.currentRoute.route.beforeEnter &&
        f7.views.main.router.currentRoute.path !== "/" &&
        !this.authenticated
      ) {
        link = f7.views.main.router.currentRoute.path;
        console.log("link ", link);
      } else {
        link =
          window.location.href.search("/#") >= 0
            ? window.location.href.split("/#").pop().split("?")[0]
            : window.location.href.split(".com").pop().split("?")[0];
      }
      if (!this.onboardingFinished) {
        this.$store.dispatch("auth/setOnboardingFinished", true);
      }
      console.log("link ", link);
      // if (link !== "" && link != undefined && link !== "/") {
      // setTimeout(() => {
      // f7.dialog.confirm(
      //   this.$t("common.youareNotLoggeIn_LogIn"),
      //   () => {
      f7.views.main.router.navigate(link);
      // setTimeout(() => {
      //   f7.preloader.hide();
      //   f7.popup.close(".startPopup");
      // }, 500);
      // return;
      // },
      //     () => {
      //       f7.views.main.router.navigate("/");
      //       // setTimeout(() => {
      //       f7.popup.close(".startPopup");
      //       // }, 500);
      //     }
      //   );
      //   return;
      // }
      // console.log("no link ", link);
      f7.preloader.hide();
      // f7.popup.close(".startPopup");
      // f7.views.main.router.navigate("/");
    },
    redirectOnGuardedRoute() {
      if (this.onboardingFinished) {
        this.$store.dispatch("auth/setOnboardingFinished", true);
      }
      const currentRoute = f7.views.main.router.currentRoute;
      const isLocalhost = window.location.origin.startsWith(
        "http://localhost:5173"
      );
      const isAvocash = window.location.origin.startsWith(
        "https://avocash.com"
      );
      const isUnauthenticated = !this.$keycloak.authenticated;
      const isEmptyHash = window.location.hash === "";
      const isNotHomePage = currentRoute.path !== "/";

      let link = null;
      if (
        (isLocalhost || isAvocash) &&
        isEmptyHash &&
        isNotHomePage &&
        isUnauthenticated
      ) {
        link = window.location.pathname + (window.location.search || "");
      } else if (
        // currentRoute.route.beforeEnter &&
        isNotHomePage &&
        isEmptyHash &&
        isUnauthenticated
      ) {
        link = currentRoute.path;
      }
      console.log(
        "f7.views.main.router.currentRoute",
        f7.views.main.router.currentRoute,
        // f7.views.main.router.currentRoute.route.beforeEnter,
        link,
        !this.$keycloak.authenticated,
        this.onboardingFinished
      );
      if (link === null) return;

      f7.popup.open(".startPopup");
      f7.preloader.show("multi");
      // f7.dialog.alert("New link 0", link);
      setTimeout(() => {
        // f7.dialog.alert("New link 1", link);
        f7.views.main.router.navigate(link, {
          animate: false,
          transition: "f7-fade",
        });
        f7.preloader.hide();
        f7.popup.close(".startPopup");
      }, 1000);
    },
  },
  computed: {
    ...mapState("auth", [
      "profile",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "authenticated",
      "onboardingFinished",
      "locale",
    ]),
  },
};
