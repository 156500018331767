<template>
  <f7-popup
    class="sendMessageToUserPopup"
    style="--f7-sheet-bg-color: #fff"
    swipe-to-close
    :tablet-fullscreen="true"
    :params="{
      backdrop: true,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }"
  >
    <f7-view>
      <f7-page name="sendMessage">
        <f7-navbar
          sliding
          no-shadow
          :back-link="$t('common.backButton')"
          :back-link-show-text="false"
          popup-close="sendMessageToUserPopup"
        >
          <f7-nav-right>
            <f7-link popup-close="sendMessageToUserPopup">
              <f7-icon
                ios="f7:multiply"
                aurora="f7:multiply"
                md="material:close"
              ></f7-icon>
            </f7-link>
          </f7-nav-right>
          <f7-nav-title>{{ $t("common.sendMessages") }}</f7-nav-title>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block class="cols95_05">
              <div v-if="msgRecipients.length > 0">
                <f7-chip
                  class="margin-left-half"
                  outline
                  v-for="(participant, index) in msgRecipients"
                  :key="index"
                  :text="participant.name"
                  media-bg-color="primary"
                  deleteable
                  :media="
                    participant.avatar
                      ? ''
                      : $filtersGetInitial.getInitial(participant.name)
                  "
                  @click="deleteChip(participant)"
                >
                  <template #media>
                    <img
                      v-if="participant.avatar"
                      :src="`data:image/png;base64,${participant.avatar}`"
                      style="
                        width: var(--f7-chip-height);
                        height: var(--f7-chip-height);
                        border-radius: 25px;
                      "
                    />
                  </template>
                </f7-chip>
              </div>
              <f7-link
                icon-ios="f7:plus_circle"
                icon-md="material:add_circle"
                :text="msgRecipients.length === 0 ? $t('common.clickToAddUser') : ''"
              ></f7-link>
            </f7-block>
            <f7-list dividers-ios strong-ios media-list>
              <f7-list-item
                :header="$t('common.selectRecipients')"
                class="selectRecipients display-none"
                smart-select
                :smart-select-params="{
                  openIn: !isTablet || f7.device.cordova ? 'popup' : 'page',
                  searchbar: true,
                  searchbarPlaceholder: $t('common.findRecipients'),
                }"
              >
                <select
                  name="selectMembers"
                  v-model="msgRecipients"
                  required
                  validate
                  multiple
                >
                  <option
                    v-for="(participant, index) in memberList"
                    :key="index"
                    :value="participant"
                    :data-option-image="
                      participant.avatar
                        ? `data:image/png;base64,${participant.avatar}`
                        : $filtersGetInitial.getInitial(participant.name)
                    "
                    width="24"
                  >
                    {{ participant.name }}
                    {{ participant.phoneNumber ? "+" + participant.phoneNumber : "" }}
                    {{ participant.email ? participant.email : "" }}
                  </option>
                </select>
              </f7-list-item>
              <f7-list-input
                :label="$t('common.messageSubject')"
                type="text"
                name="messageSubject"
                v-model="messageSubject"
                required
                validate
                clear-button
              />
              <f7-text-editor
                :placeholder="$t('common.messageContent')"
                :value="messageBody"
                @texteditor:change="(v) => (messageBody = v)"
                resizable
                required
                validate
                type="texteditor"
                :mode="
                  f7.device.cordova || !this.isTablet ? 'keyboard-toolbar' : 'toolbar'
                "
                :style="
                  f7.device.cordova || !isTablet ? '--f7-text-editor-height: 150px' : ''
                "
              ></f7-text-editor>
              {{ this.textBody }}
              <f7-list-item
                @click="attachFileOpenActions()"
                link="#"
                class="attachFileButton"
                :title="$t('common.uploadFiles')"
              >
              </f7-list-item>
              <f7-list-group v-if="documentsUrl && documentsUrl.length > 0">
                <span v-for="(docItem, index) in documentsUrl" :key="index">
                  <f7-list-item :title="docItem.name">
                    <template #media>
                      <f7-link
                        raised
                        :style="{
                          right: '60px',
                        }"
                        icon-f7="pencil_circle"
                        @click="editImage(docItem.id)"
                      ></f7-link>
                      <f7-link
                        @click="removeDoc(index)"
                        raised
                        color="red"
                        icon-f7="multiply_circle_fill"
                      ></f7-link>
                    </template>
                  </f7-list-item>
                </span>
              </f7-list-group>
            </f7-list>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<style scoped></style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavRight,
  f7NavTitle,
  f7Link,
  f7Icon,
  f7Block,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7ListInput,
  f7TextEditor,
  f7Popup,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { attachDocs } from "../../js/mixins/attachDocs.js";
import { cordovaCamera } from "../../js/mixins/cordovaCamera.js";
import editDocs from "./../editDocs.vue";
import { $ } from "dom7";
import _ from "lodash";

export default {
  props: {
    f7route: Object,
    f7router: Object,
    memberList: Array,
    entityType: String,
    entityId: String,
    currentUser: Object,
  },
  mixins: [attachDocs, cordovaCamera],
  components: {
    editDocs,
    f7Page,
    f7Navbar,
    f7NavRight,
    f7NavTitle,
    f7Link,
    f7Icon,
    f7Block,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7ListInput,
    f7TextEditor,
    f7Popup,
  },
  data() {
    return {
      theme,
      f7,
      messageBody: "",
      textBody: "",
      messageSubject: "",
      msgRecipients: [],
      msgRecipientsToPost: [],
      attachments: [],
      to: [],
      sendToGroup: false,
      textBody: "",
      sendToGroup: false,
    };
  },

  mounted() {},
  methods: {
    selectRecipient(user) {
      const userIndexed = this.msgRecipients.find(
        (userx) => userx.userId === user.userId
      );
      if (userIndexed) {
        return false;
      }
      const currentUser = { userId: user.userId };
      if (user.email !== null) {
        this.to.push(user.email);
      }
      const displayedUser = this.users.find((userx) => userx.id === user.userId);
      this.msgRecipients.push(displayedUser);
      this.msgRecipientsToPost.push(currentUser);
    },
    deleteChip(user) {
      const index = this.msgRecipients.indexOf(user);
      this.msgRecipients.splice(index, 1);
      this.msgRecipientsToPost.splice(index, 1);
      if (user.email !== null) {
        const index0 = this.to.indexOf(user.email);
        this.to.splice(index0, 1);
      }
    },
    async sendMessage() {
      try {
        const data = {
          subject: this.messageSubject,
          body: this.messageBody,
          messageRecipients: this.msgRecipientsToPost,
          from: this.currentUser.email,
          to: this.to,
          attachments: this.attachments,
          textBody: $(this.messageBody).text(),
          template: "generic-email-template.ftlh",
          groupId: this.entityId,
          sendToGroup: false,
          locale: this.locale,
        };
        const response = await this.$store.dispatch("message/sendMessage", data);
        if (response.status === 200) {
          this.$f7.dialog.alert(this.$t("common.messageSent"));
          this.$f7.dialog.close();
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t("common.messageNotSent"));
      }
    },
    async attachFileOpenActions() {
      const actions = [
        {
          text: this.$t("common.takePhoto"),
          iconF7: "camera_fill",
          onClick: () => {
            this.attachFileFromCamera();
          },
        },
        {
          text: this.$t("common.chooseFromGallery"),
          onClick: () => {
            // Implement gallery selection logic
          },
        },
      ];
      // Implement action sheet or dialog to show these actions
    },
  },
  computed: {
    ...mapState("auth", [
      "profile",
      "isDeskTop",
      "isTablet",
      "locale",
      "documentsUrl",
      "document",
    ]),
    ...mapState("account", ["userDetail", "userAccount", "users"]),
    ...mapState("location", ["destinationCity", "country"]),
    countries() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
