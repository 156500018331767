import { f7 } from "framework7-vue";
import { mapState } from "vuex";

const PWA = {
  data() {
    return {
      skipWaitingSent: false,
      registrat: null,
      serviceWorkerPendingUpdate: false,
      selectedMessages: [],
      refreshing: false,
      updated: false,
      activated: false,
      updatePWAToast: "",
      updatePWAPopver: "",
    };
  },
  mounted() {
    if (window.cordova || window.Cordova) {
      return;
    }

    this.initPWA();
    // setTimeout(() => {
    //   if (this.isTablet) {
    //     this.updatePWAPopver.close();
    //   } else {
    //     this.updatePWAToast.close();
    //   }
    // }, 3000000);
    const updateBtn = document.querySelector(".pwa-update-notif-button");
    if (updateBtn) {
      updateBtn.addEventListener("click", (e) => {
        f7.dialog.alert("Updating app 1", e, updateBtn);
        if (this.registrat && this.registrat.waiting) {
          this.registrat.waiting.postMessage("SKIP_WAITING");
          // window.location.reload();
        }
      });
    }
    f7.$(".pwa-update-notif-button").click(function (e) {
      f7.dialog.alert("Updating app 1", e, updateBtn);
      if (this.registrat && this.registrat.waiting) {
        this.registrat.waiting.postMessage("SKIP_WAITING");
        // window.location.reload();

        // if (this.isTablet) {
        //   this.updatePWAPopver.close();
        // } else {
        this.updatePWAToast.close();
        // }
      }
      e.stopPropagation();
    });
  },
  methods: {
    initPWA() {
      if (
        "serviceWorker" in window.navigator &&
        process.env.NODE_ENV === "production"
      ) {
        window.navigator.serviceWorker
          .register(`/service-worker.js`, {
            scope: "/",
          })
          .then((registration) => {
            this.registrat = registration; // Store registration for later use
            console.log("Service Worker registered");
            registration.addEventListener("updatefound", () => {
              const worker = registration.installing;
              worker.addEventListener("statechange", () => {
                console.log({ state: worker.state });
                if (worker.state !== "activated") {
                  return;
                }
                this.activated = true;
                console.log("Service Worker activated");
                // window.location.reload();
                // this.checkUpdate();
              });
            });
          })
          .catch((error) => {
            console.error("Service worker registration failed:", error);
          });
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          console.log("Service Worker controllerchange  ");
          this.updated = true;
          // this.checkUpdate();
        });
      }
    },
    checkUpdate() {
      if (this.activated && this.updated) {
        // f7.dialog.confirm(this.$t("common.updateNewContent"), () => {
        //   if (this.registrat && this.registrat.waiting) {
        //     this.registrat.waiting.postMessage("SKIP_WAITING");
        //     window.location.reload();
        //     // this.updatePWAToast.close();
        //   }
        // });
        //   if (this.isTablet) {
        //     if (!this.updatePWAToast) {
        //       this.updatePWAToast = f7.notification.create({
        //         closeButton: true,
        //         // closeOnEscape: true,
        //         // swipeToClose: true,
        //         // backdrop: true,
        //         icon: '<i class="icon elevation-3"><img src="/32x32.png" width="32" height="32" alt="avocash icon"></i>',
        //         title:
        //           "<span class='avocash color-primary' style='text-transform:lowercase; color: var(--f7-theme-color); font-weight: bold'>avo¢ash</span>",

        //         // push: true,
        //         subtitle: `<span>${this.$t(
        //           "common.updateNewContent"
        //         )}</span><a class="button button-small button-outline  button-round color-primary
        //       button-raised pwa-update-notif-button margin-left" style="border-radius: 32px;
        //       border-width: medium; " @click="updateApp()" id="pwa-update-notif-button">${this.$t(
        //         "common.update"
        //       )}</a>`,
        //         // closeButton: true,
        //         // horizontalPosition: "center",
        //         // closeButtonText: this.$t("common.no"),
        //         // closeButtonColor: "red",
        //         // position: f7.width > 768 ? "top" : "bottom",
        //         closeTimeout: 3000000,
        //         on: {
        //           close() {
        //             // registration.waiting.postMessage("SKIP_WAITING");
        //             // window.location.reload();
        //           },
        //           //   click() {
        //           //     registration.waiting.postMessage("SKIP_WAITING");
        //           //     window.location.reload();
        //           //   },
        //         },
        //       });
        //     }
        //     // Open it
        //     this.updatePWAToast.open();
        //   }
        // } else {
        if (!this.updatePWAToast) {
          this.updatePWAToast = f7.toast.create({
            destroyOnClose: true,
            // closeOnEscape: true,
            // swipeToClose: true,
            // backdrop: false,
            // verticalPosition: "top",
            // targetEl: self.authenticated ? "#dashboard" : "#stores",
            // arrow: true,
            // push: true,
            text: `<span>
                    <i class="icon"><img src="/24x24.png" width="24" height="24" alt="avocash icon"></i>
                    <span class='avocash color-primary margin-left-half' style='text-transform:lowercase; color: var(--f7-theme-color); font-weight: bold'>avo¢ash</span>
                  </span>
                 <span class="margin" style="font-size:${
                   this.isTablet ? "100%" : "small"
                 }" >${this.$t("common.updateNewContent")}</span>
                  `,
            horizontalPosition: "center",
            closeButton: true,
            closeButtonText: `<a class="button toast-button button-small button-outline button-round color-primary border-color-primary pwa-update-notif-button
                  button-raised" style="border-radius: 32px; border-width: medium;" id="pwa-update-notif-button">${this.$t(
                    "common.update"
                  )}</a>`,
            closeButtonColor: "primary",
            position: this.isTablet ? "top" : "bottom",
            closeTimeout: 3000000,
            on: {
              close() {
                // f7.dialog.alert("Updating app 1");
                if (!(this.registrat && this.registrat.waiting)) {
                  return;
                }
                this.registrat.waiting.postMessage("SKIP_WAITING");
                window.location.reload();

                // if (this.isTablet) {
                //   this.updatePWAPopver.close();
                // } else {
                this.updatePWAToast.close();
              },
              // click() {
              //   registration.waiting.postMessage("SKIP_WAITING");
              //   window.location.reload();
              // },
            },
          });
        }
        this.updatePWAToast.open();
        // Open it
      }
    },
    updateApp() {
      if (!(this.registrat && this.registrat.waiting)) {
        return;
      }
      f7.dialog.alert("Updating app 2");
      this.registrat.waiting.postMessage("SKIP_WAITING");
      window.location.reload();
      // if (this.isTablet) {
      // this.updatePWAPopver.close();
      // } else {
      this.updatePWAToast.close();
    },
  },
  computed: {
    ...mapState("auth", [
      "video",
      "isDeskTop",
      "isTablet",
      "device",
      "authenticated",
    ]),
  },
};

export default PWA;
