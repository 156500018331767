<template>
  <f7-page class="home_page">
    <f7-navbar no-shadow v-if="!this.isTablet">
      <f7-nav-left v-if="theme.ios" @click="showOnbordingPage()">
        <img
          src="/32x32.png"
          alt="avocash logo"
          width="32"
          height="32"
          style="vertical-align: middle"
        />
        <span
          class="avocash text-color-primary padding-left-half"
          style="
            letter-spacing: var(--f7-tabbar-label-letter-spacing);
            font-weight: bold;
            font-size: large;
          "
          >avo¢ash</span
        >
      </f7-nav-left>
      <f7-nav-title @click="showOnbordingPage()" v-if="theme.md">
        <img
          src="/32x32.png"
          alt="avocash logo"
          width="32"
          height="32"
          style="vertical-align: middle"
        />
        <span
          class="avocash text-color-primary padding-left-half"
          style="
            letter-spacing: var(--f7-tabbar-label-letter-spacing);
            font-weight: bold;
            font-size: large;
          "
          >avo¢ash</span
        >
      </f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <div class="layout_content">
      <div class="left_layout">
        <div class="sticky with_content" v-if="this.isTablet"></div>
      </div>
      <div class="main_layout">
        <f7-list dividers-ios strong-ios class="searchbar-not-found">
          <f7-list-item
            :title="$t('common.nothingFound')"
            style="font-size: 18px; color: red"
          ></f7-list-item>
        </f7-list>

        <f7-card :padding="false" header-divider outline>
          <f7-card-header class="homeCardHeader">
            <f7-block-title medium class="homeCardHeaderBlock">
              <b>
                <span class="avocash">avo¢ash </span
                >{{ $t("homepage.avocashSocialMoney") }}</b
              >
              <h5 class="homeCardHeaderText item-label">
                {{ $t("homepage.avocashSocialMoneyDetails") }}
              </h5>
            </f7-block-title>
          </f7-card-header>
          <f7-card-content
            no-gap
            class="text-align-center margin-bottom-half grid grid-cols-4"
          >
            <a href="/tontine/new">
              <f7-icon
                :size="size"
                ios="f7:circle_grid_hex_fill"
                aurora="f7:circle_grid_hex_fill"
                md="material:hive"
                class="iconHomeCommunity"
              ></f7-icon>
              <div class="link-name">
                {{ $t("homepage.tontineanSavingsCircles") }}
              </div>
            </a>

            <a href="/association/new/group">
              <f7-icon
                :size="size"
                md="material:groups"
                aurora="f7:person_3_fill"
                ios="f7:person_3_fill"
                class="iconHomeCommunity"
              ></f7-icon>
              <div class="link-name" style="white-space: normal; word-wrap: break-word">
                {{ $t("homepage.groupFriendFamily") }}
              </div>
            </a>

            <a href="/association/new/association">
              <f7-icon
                :size="size"
                ios="material:group_work"
                aurora="material:group_work"
                md="material:group_work"
                class="iconHomeCommunity"
              ></f7-icon>
              <div class="link-name" style="white-space: normal; word-wrap: break-word">
                {{ $t("homepage.associations") }}
              </div>
            </a>

            <a href="/wallet/new/charities">
              <f7-icon
                :size="size"
                ios="material:volunteer_activism"
                aurora="material:volunteer_activism"
                md="material:volunteer_activism"
                class="iconHomeCommunity"
              ></f7-icon>
              <div class="link-name">{{ $t("homepage.charitiesFunding") }}</div>
            </a>
            <a></a>
            <a href="/wallet/new/entrepreneurs">
              <f7-icon
                :size="size"
                aurora="f7:sunrise"
                ios="f7:sunrise"
                md="f7:sunrise"
                class="iconHomeComunityOne"
              >
              </f7-icon>
              <div class="link-name">
                {{ $t("homepage.webEntrepreneurs") }}
              </div>
            </a>

            <a href="/wallet/new/creatives">
              <f7-icon
                :size="size"
                aurora="material:history_edu"
                ios="material:history_edu"
                md="material:history_edu"
                class="iconHomeComunityOne"
              ></f7-icon>
              <div class="link-name">
                {{ $t("homepage.artistsWebArtits") }}
              </div>
            </a>

            <a href="/wallet/new/moneypots">
              <f7-icon
                :size="size"
                ios="material:reduce_capacity"
                aurora="material:reduce_capacity"
                md="material:reduce_capacity"
                class="iconHomeComunityOne"
              ></f7-icon>
              <div class="link-name padding-left-half">
                {{ $t("homepage.soloPubliccampaigns") }}
              </div>
            </a>
          </f7-card-content>
        </f7-card>
        <f7-card :padding="false" header-divider outline>
          <f7-card-header class="homeCardHeader">
            <f7-block-title medium class="homeCardHeaderBlock">
              <b>
                <span class="avocash">avo¢ash </span
                >{{ $t("homepage.avocashSocialCommerce") }}</b
              >
              <h5 class="homeCardHeaderText item-label">
                {{ $t("homepage.socialCommerceDetail") }}
              </h5>
            </f7-block-title>
          </f7-card-header>
          <f7-card-content
            no-gap
            class="text-align-center margin-bottom-half grid grid-cols-4"
          >
            <a href="/business-qr/">
              <f7-icon
                :size="size"
                ios="material:point_of_sale"
                aurora="material:point_of_sale"
                md="material:point_of_sale"
                class="iconHomeClaim"
              ></f7-icon>
              <div class="link-name">{{ $t("homepage.businessPOSPayment") }}</div>
            </a>
            <a href="/shop/new">
              <f7-icon
                :size="size"
                ios="material:add_business"
                aurora="material:add_business"
                md="material:add_business"
                class="iconHomeClaim"
              ></f7-icon>

              <div class="link-name">{{ $t("homepage.createStore") }}</div>
            </a>
            <a :href="'/product/new/' + shopId">
              <f7-icon
                :size="size"
                ios="material:discount"
                aurora="material:discount"
                md="material:discount"
                class="iconHomeClaim"
              ></f7-icon>
              <div class="link-name">{{ $t("homepage.sellOnline") }}</div>
            </a>

            <!-- <a href="/create/delivery/account">
              <f7-icon :size="size" ios="material:delivery_dining" aurora="material:delivery_dining"
                md="material:delivery_dining" class="iconHomeClaim"></f7-icon>
              <div class="link-name">{{ $t("homepage.deliver") }}</div>
            </a> -->

            <div></div>
            <div></div>
            <div></div>
            <!-- <a href="/create/pickup/account">
              <f7-icon :size="size" ios="f7:placemark" aurora="f7:placemark" md="material:place" class="iconHomeClaim">
              </f7-icon>
              <div class="link-name padding-left-half">
                {{ $t("homepage.pickupPoint") }}
              </div>
            </a> -->
          </f7-card-content>
        </f7-card>
        <!-- <f7-card :padding="false" header-divider outline>
          <f7-card-header class="homeCardHeader">
            <f7-block-title medium class="homeCardHeaderBlock">
              <strong>
                <span class="avocash">avo¢ash </span>{{ $t("homepage.avocashInstantPay") }}</strong>
              <h5 class="homeCardHeaderText item-label">
                {{ $t("homepage.avocashInstantPayDetail") }}
              </h5>
            </f7-block-title>
          </f7-card-header> -->
        <!-- <f7-card-content no-gap class="text-align-center margin-bottom-half grid grid-cols-4">
            <a href="/pay-cash/">
              <f7-icon :size="size" aurora="f7:creditcard" ios="f7:creditcard" md="material:credit_card" color="primary"
                class="iconHomePay"></f7-icon>

              <div class="link-name">{{ $t("homepage.instantpay") }}</div>
            </a>
            <a href="#" @click="goToWithdrawTab()">
              <f7-icon :size="size" aurora="material:money" ios="material:money" md="material:money" class="iconHomePay">
              </f7-icon>
              <div class="link-name">{{ $t("homepage.withdraw") }}</div>
            </a>
            <a href="#" @click="goToPickupATM()">
              <f7-icon :size="size" aurora="f7:wallet" ios="f7:wallet" md="material:account_balance_wallet"
                class="iconHomePay">
              </f7-icon>

              <div class="link-name">{{ $t("homepage.remitPickup") }}</div>
            </a>
            <a href="/card/new">
              <f7-icon :size="size" aurora="f7:square_arrow_up_on_square" ios="f7:square_arrow_up_on_square"
                md="material:ios_share" class="iconHomePay">
              </f7-icon>

              <div class="link-name">{{ $t("homepage.issueTopup") }}</div>
            </a>
          </f7-card-content>
        </f7-card> -->
        <f7-card class="app-list" :padding="false" header-divider outline>
          <f7-card-header class="homeCardHeader">
            <f7-block-title medium class="homeCardHeaderBlock">
              <strong>
                <span class="avocash">avo¢ash </span
                >{{ $t("homepage.avocashRemit") }}</strong
              >
              <h5 class="homeCardHeaderText item-label">
                {{ $t("homepage.avocashRemitDetail") }}
              </h5>
            </f7-block-title>
          </f7-card-header>
          <f7-card-content
            no-gap
            class="text-align-center margin-bottom-half grid grid-cols-4"
          >
            <a href="/recipients">
              <f7-icon
                :size="size"
                aurora="f7:paperplane"
                ios="f7:paperplane"
                md="material:send"
                class="iconHomeSend sendIcon"
              ></f7-icon>
              <div class="link-name">
                {{ $t("homepage.sendPickup") }}
              </div>
            </a>
            <a href="/recipients/topup-all">
              <f7-icon
                :size="size"
                aurora="f7:arrow_up_right"
                ios="f7:arrow_up_right"
                md="material:system_update_alt"
                :style="{ transform: theme.md ? 'rotate(180deg)' : '' }"
                class="iconHomeSend"
              ></f7-icon>
              <div class="link-name">{{ $t("homepage.sendTopup") }}</div>
            </a>
            <a href="/transfer/for-purpose">
              <f7-icon
                :size="size"
                aurora="f7:document_text_fill"
                ios="f7:document_text_fill"
                md="f7:document_text_fill"
                class="iconHomeSend"
              ></f7-icon>
              <div class="link-name">{{ $t("homepage.remitForPurpose") }}</div>
            </a>
            <a href="/mobile/topup">
              <f7-icon
                :size="size"
                aurora="material:system_update"
                ios="material:system_update"
                md="material:system_update"
                style="transform: rotate(180deg)"
                class="iconHomeSend"
              ></f7-icon>
              <div class="link-name">
                {{ $t("homepage.mobileTopup") }}
              </div>
            </a>
          </f7-card-content>
        </f7-card>
      </div>
      <div class="right_layout">
        <div class="sticky with_content" v-if="this.isTablet"></div>
      </div>
    </div>
  </f7-page>
</template>
<style scoped>
@media screen and (min-width: 768px) {
  .homePage {
    width: 60% !important;
    margin-left: 20% !important;
    min-width: 768px;
  }
}

.homeCardHeader {
  padding: 0px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.homeCardHeaderBlock {
  white-space: normal;
  word-wrap: break-word;
  margin-bottom: 8px;
  margin-top: 8px;
}

.homeCardHeaderText {
  margin-bottom: 8px;
  margin-top: 8px;
  font-style: italic;
}

.homeTabIconText {
  display: block;
  line-height: 1;
  margin: 0;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--f7-tabbar-label-font-size);
  text-transform: none;
  text-transform: var(--f7-tabbar-label-text-transform);
  font-weight: var(--f7-tabbar-label-font-weight);
  letter-spacing: var(--f7-tabbar-label-letter-spacing);
  color: var(--f7-tabbar-link-inactive-color);
  text-transform: lowercase;
}

.sendIcon {
  transform: rotate(-30deg);
}

i.icon {
  font-size: 1.5rem;
  color: white;
  position: relative;
  border-radius: 50%;
  padding: 5px;
  margin: 3px;
}

.iconHomeSend {
  background-color: blue;
  box-shadow: 0px 0px 2px blue;
}

.iconHomePay {
  background-color: red;
  box-shadow: 0px 0px 2px red;
}

.iconHomeClaim {
  background-color: deeppink;
  box-shadow: 0px 0px 2px deeppink;
}

.iconHomeCashOut {
  background-color: orangered;
  box-shadow: 0px 0px 2px orangered;
}

.iconHomeATM {
  background-color: orange;
  box-shadow: 0px 0px 2px orange;
}

.iconHomeCommunity {
  background-color: green;
  box-shadow: 0px 0px 2px green;
}

.iconHomeComunityOne {
  background-color: teal;
  box-shadow: 0px 0px 2px teal;
}

.link-name {
  font-size: 80%;
  margin-bottom: 8px;
}
</style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Card,
  f7CardHeader,
  f7BlockTitle,
  f7Icon,
  f7,
  theme,
} from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Card,
    f7CardHeader,
    f7BlockTitle,
    f7Icon,
  },

  data() {
    return {
      theme,
      f7,
      title: "avo¢ash send & pay",
      destinationctryId: "",
      pickupcity: "",
      pickupplace: "",
      pickupMethod: "",
      transferRateId: "",
      recipientId: "",
      tocurrencycode: "",
      query: "",
      grossXrate: "",
      recipientacctnumber: "",
      recipientAccountType: "",
      accountNumber: "",
      timeOut: 500,
      size: "42px",
      shopId: "noShop",
      theme,
      f7,
    };
  },
  mounted() {
    if (this.theme.ios) {
      f7.$(".card").addClass("outline");
    }
    if (this.isDeskTop == false) {
      f7.$(".home_page .page-content").addClass("hide-bars-on-scroll");
    }
    f7.$(".mainToolbar").css("display", "block !important");
    this.size = this.isTablet ? "48px" : "42px";
  },

  methods: {
    showOnbordingPage() {
      this.$lcStorage.set("ql82uFo", false);
      this.$store.dispatch("auth/setOnboardingFinished", false);
      if (!this.onboardingFinished && f7.device.cordova) {
        f7.statusbar.show();
      }
    },
    goToWithdrawTab() {
      f7.views.main.router.navigate("/pay-cash");
      setTimeout(() => {
        f7.tab.show("#payWithavocashNumber");
      }, 10);
    },

    goToPickupATM() {
      f7.views.main.router.navigate("/atm/pickup", {
        props: {
          isNotTab: "false",
        },
      });
    },
  },

  computed: {
    ...mapState("auth", [
      "profile",
      "token",
      "user",
      "authenticated",
      "topath",
      "phoneNumberDetail",
      "mobilePhoneError",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
      "lastSwiperIsActive",
      "onboardingFinished",
    ]),
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
  },
};
</script>
