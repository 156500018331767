// import base_url from "../base_url.js";
import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "account/user";
const doc_path = import.meta.env.VITE_APP_URL + "document";
export default (app) => {
  return {
    // const account = {
    namespaced: true,
    state: {
      userByAccountNumber: "",
      claimUserByAccountNumber: "",
      accountnumber: "",
      userDetail: "",
      primaryAccount: "",
      businessAccount: "",
      getusererror: "",
      users: "",
      searchedUser: "",
      userAccount: "",
      document: "",
      documentList: [],
    },
    getters: {
      userByAccountNumber: (state) => state.userByAccountNumber,
      claimUserByAccountNumber: (state) => state.claimUserByAccountNumber,
    },
    mutations: {
      CREAT_NEW_USER_AVOCASH_ACCOUNT(state) {
        state.status = "account created";
      },
      GET_USER(state, userDetail) {
        state.userDetail = userDetail;
      },
      GET_USER_BY_CRITERIA(state, searchedUser) {
        state.searchedUser = searchedUser;
      },
      GET_ALL_USERS(state, users) {
        state.users = users;
      },
      SET_USER(state, searchedUser) {
        state.searchedUser = searchedUser;
      },
      GET_USERACCOUNT(state, userAccount) {
        state.userAccount = userAccount;
      },
      GET_BUSINESSACCOUNT(state, userAccount) {
        state.userAccount = userAccount;
      },
      GET_USERBYACCOUNTNUMBER(state, userByAccountNumber) {
        state.userByAccountNumber = userByAccountNumber;
      },
      GET_CLAIMUSERBYACCOUNTNUMBER(state, claimUserByAccountNumber) {
        state.claimUserByAccountNumber = claimUserByAccountNumber;
      },
      GETUSERBYACCTNBR_ERROR(state, getusererror) {
        state.getusererror = getusererror;
      },
      RESET_USERBYACCOUNTNUMBER(state) {
        state.userByAccountNumber = "";
      },
      GET_DOCUMENTS(state, documentList) {
        state.documentList = documentList;
      },
    },

    actions: {
      createNewUservocashAccounts({ commit }) {
        axios({
          url: `${path}/create`,
          method: "POST",
          data: app.config.globalProperties.$keycloak.userInfo,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            if (userAccount?.url && userAccount.url !== null) {
              window.location = userAccount.url;
            } else {
              commit("GET_USERACCOUNT", userAccount);
              // if (userAccount && userAccount.standard !== null) {
              //   dispatch("apimoney/createStandardAccount", { root: true });
              // }
            }
            console.log("moduleCreate", userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserAccount({ commit }, payload) {
        axios({
          url: `${path}/current`,
          method: "POST",
          data:
            payload !== null
              ? payload
              : app.config.globalProperties.$keycloak.userInfo,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            console.log("userAccount is ", userAccount);
            const account = userAccount.body ? userAccount.body : userAccount;
            // if (userAccount) {
            commit("GET_USERACCOUNT", account);
            console.log("userAccount body is ", account);
            // } else {
            //   commit("GET_USERACCOUNT", userAccount.body.body);
            //   console.log("userAccount is ", userAccount.body.body);
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      uploadProofOfId({ commit }, payload) {
        axios({
          url: `${path}/upload/proofOfId`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
      uploadLogoAvatar({ commit }, payload) {
        axios({
          url: `${path}/logo`,
          method: "POST",
          data: payload.forms,
          params: {
            accountId: payload.accountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
            console.log(userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      updateLogoAvatar({ commit }, payload) {
        axios({
          url: `${path}/logo`,
          method: "PUT",
          data: payload.forms,
          params: {
            accountId: payload.accountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
            console.log(userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      activateBusinessAccount({ commit }, payload) {
        axios({
          url: `${path}/activateBusinessAccount`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((businessAccount) => {
            if (
              businessAccount &&
              businessAccount.url &&
              businessAccount.url !== null
            ) {
              window.location = businessAccount.url;
            } else {
              commit("GET_BUSINESSACCOUNT", businessAccount);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      changePublicStatus({ commit }, payload) {
        axios({
          url: `${path}/publicStatus`,
          method: "PUT",
          params: {
            accountId: payload.accountId,
            publicAccount: payload.publicAccount,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      setPaymentLimit({ commit }, payload) {
        axios({
          url: `${path}/paymentLimit`,
          method: "PUT",
          params: {
            accountId: payload.accountId,
            paymentLimit: payload.paymentLimit,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      setAccounMaintWallet({ commit }, payload) {
        axios({
          url: `${path}/wallet`,
          method: "PUT",
          params: {
            accountId: payload.accountId,
            walletId: payload.walletId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      setAccounMaintCreditCard({ commit }, payload) {
        axios({
          url: `${path}/creditcard`,
          method: "PUT",
          params: {
            accountId: payload.accountId,
            creditcardId: payload.creditcardId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      setAccounMaintBankAccount({ commit }, payload) {
        axios({
          url: `${path}/bankaccount`,
          method: "PUT",
          params: {
            accountId: payload.accountId,
            bankAccountId: payload.bankAccountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      loadAccount({ commit }, payload) {
        axios({
          url: `${path}/getAccountByUserId`,
          method: "GET",
          params: {
            userId:
              payload !== null
                ? payload
                : app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
            console.log(userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      loadBusinessAccount({ commit }) {
        axios({
          url: `${path}/businessAccount`,
          method: "GET",
          params: app.config.globalProperties.$keycloak.subject,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userAccount) => {
            commit("GET_USERACCOUNT", userAccount);
            console.log(userAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      loadUser({ commit }, payload) {
        let type_params;
        let type_url;
        if (payload.type === "userId") {
          type_params = { userId: payload.userId };
          type_url = "/userAccountsDetailsByUserId";
        } else if (payload.type === "accountId") {
          type_params = { accountId: payload.accountId };
          type_url = "/accountId";
        } else if (payload.type === "walletId") {
          type_params = { walletId: payload.walletId };
          type_url = "/userAccountsDetailsByWalletId";
        }
        console.log("userDetail params", type_params, type_url);
        axios({
          url: `${path}${type_url}`,
          method: "GET",
          params: type_params,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userDetail) => {
            commit("GET_USER", userDetail);
            console.log("userDetail", userDetail);
          })
          .catch((err) => {
            console.log(err.response.data);
            console.log(err);
          });
      },
      getAllUsers({ commit }) {
        axios({
          url: `${path}/allUsers`,
          method: "GET",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          useCache: true,
        })
          .then((r) => r.data)
          .then((users) => {
            commit("GET_ALL_USERS", users);
            console.log(users);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      setUser({ commit }, searchedUser) {
        console.log(searchedUser);
        commit("SET_USER", searchedUser);
      },

      getUserByAccountNumber({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/accountId`,
          method: "GET",
          params: {
            accountId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then(() => {
            commit("GET_USERBYACCOUNTNUMBER", userAccount);
          })
          .catch((err) => {
            console.log(err.response);
          });
      },

      //get user for claiming payment
      getClaimUserByAccountNumber({ commit }, payload) {
        axios({
          url: `${path}/userbyaccountnumber`,
          method: "GET",
          params: {
            accountNumber: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((claimUserByAccountNumber) => {
            commit("GET_CLAIMUSERBYACCOUNTNUMBER", claimUserByAccountNumber);
            console.log(claimUserByAccountNumber);
            commit("GETUSERBYACCTNBR_ERROR", "");
            // console.log(payload)
          })
          .catch((err) => {
            commit("GETUSERBYACCTNBR_ERROR", err.response.data.message);
            commit("GET_CLAIMUSERBYACCOUNTNUMBER", "");
            console.log(err.response.data.message);
            console.log(err);
          });
      },
      resetUserByAccountNumber({ commit }) {
        commit("RESET_USERBYACCOUNTNUMBER");
      },

      createEntityDocuments({ commit }, payload) {
        axios({
          url: doc_path,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((documentList) => {
            commit("GET_DOCUMENTS", documentList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
      getEntityDocuments({ commit }, payload) {
        axios({
          url: doc_path,
          method: "GET",
          params: { entityId: payload.entityId },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((documentList) => {
            commit("GET_DOCUMENTS", documentList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
      editEntityDocuments({ commit }, payload) {
        axios({
          url: doc_path,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((documentList) => {
            commit("GET_DOCUMENTS", documentList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
      deleteEntityDocuments({ commit }, payload) {
        axios({
          url: doc_path,
          method: "DELETE",
          params: { docId: payload.docId, isAdmin: payload.isAdmin },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((documentList) => {
            commit("GET_DOCUMENTS", documentList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
      deleteFileEntityDocuments({ commit }, payload) {
        axios({
          url: `${doc_path}/file`,
          method: "DELETE",
          params: { fileId: payload.fileId, isAdmin: payload.isAdmin },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((documentList) => {
            commit("GET_DOCUMENTS", documentList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
    },
  };
};
// export default account;
