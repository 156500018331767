<template>
  <f7-page
    ptr
    :ptr-mousewheel="true"
    @ptr:refresh="loadMore"
    ptr-content
    ptr-bottom
    no-shadow
    :page:init="ttTabshow()"
    :page:afterout="ttTabhide()"
    class="tamtam_page"
  >
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false">
        <navbarleft v-if="this.isTablet == false"></navbarleft>
      </f7-nav-left>
      <f7-nav-title>{{ $t("tamtam.tamtam") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="this.isTablet"
          icon-aurora="f7:ellipses_bubble_fill"
          icon-ios="f7:ellipses_bubble_fill"
          icon-md="material:chat"
          @click="opensendMessageLeftPanel()"
        ></f7-link>
        <f7-link
          v-if="!this.isTablet"
          class="searchbar-enable"
          data-searchbar=".searchbar-ContactList"
          icon-aurora="f7:search_strong"
          icon-ios="f7:search_strong"
          icon-md="material:search"
        ></f7-link>
        <f7-link
          popover-open=".otherActions"
          icon-aurora="f7:ellipsis_vertical"
          icon-ios="f7:ellipsis_vertical"
          icon-md="material:more_vert"
        ></f7-link>
      </f7-nav-right>

      <f7-subnavbar
        :inner="false"
        no-shadow
        class="no-shadow"
        v-if="this.groupList && this.isTablet == true"
      >
        <f7-searchbar
          :backdrop="false"
          search-container=".chatContact-list"
          :placeholder="$t('tamtam.searchConversationMessage')"
          search-in=".item-title, .item-after, .item-text, .item-footer"
          search-group-title=".list-group-title"
          hide-groups:false
          :disable-button="!theme.aurora"
          :clear-button="true"
        ></f7-searchbar>
      </f7-subnavbar>
      <f7-searchbar
        v-if="!this.isTablet"
        class="searchbar-ContactList"
        expandable
        search-container=".chatContact-list"
        search-in=".item-title, .item-after, .item-text, .item-footer"
        search-group-title=".list-group-title"
        hide-groups:false
        :clear-button="true"
        :backdrop="false"
        :disable-button="!theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
    <span v-if="!this.chatUser">
      <a href="#" style="display: flex; justify-content: center" @click="joinAvochat()">
        <div class="chip">
          <div class="chip-media chip-outline color-red">
            <i class="icon f7-icons if-not-md">plus_circle_fill</i>
            <i class="icon material-icons md-only">add_circle</i>
          </div>
          <div class="chip-label avocash" v-html="$t('tamtam.joinAvochat')"></div>
        </div>
      </a>
      <f7-block-title medium class="avocash">
        <span v-html="$t('tamtam.joinAvochat')"> </span>
      </f7-block-title>
      <f7-list dividers-ios strong-ios media-list>
        <f7-list-item title="">{{ $t("tamtam.discussWithOther") }}</f7-list-item>
        <f7-list-item title="">{{ $t("tamtam.contactCustomersSellers") }}</f7-list-item>
        <f7-list-item title="">{{ $t("tamtam.sharePaymentAnd") }}</f7-list-item>
        <f7-list-item>{{ $t("tamtam.receiveAndRequestPayments") }}</f7-list-item>
      </f7-list>
    </span>

    <f7-list dividers-ios strong-ios v-if="!!this.chatUser && !this.groupList" media-list>
      <f7-list-item
        v-for="n in 10"
        :key="n"
        class="skeleton-text skeleton-effect-blink"
        title="Full Name"
        subtitle="Position dolor"
        after=" 6 June 2020"
        ><template #media>
          <f7-skeleton-block
            style="width: 40px; height: 40px; border-radius: 50%"
          ></f7-skeleton-block>
        </template>
      </f7-list-item>
    </f7-list>
    <f7-list
      dividers-ios
      strong-ios
      class="searchbar-not-found"
      media-list
      v-else-if="!!this.chatUser && !!this.groupList"
    >
      <f7-list-item
        :subtitle="$t('tamtam.noconversationmessageorcontactfound')"
      ></f7-list-item>
    </f7-list>
    <f7-list
      dividers-ios
      strong-ios
      media-list
      no-chevron
      menu-list
      class="chatContact-list searchbar-found no-margin"
      v-if="!!this.chatUser && !!this.groupList && this.groupList.length > 0"
      v-for="(chatGroup, index) in this.groupList"
      :key="index + '' + chatGroup.id"
    >
      <li v-if="chatGroup.groupType === 'GROUP'">
        <a
          class="item-link item-content actionsSheetTaphold"
          :href="'/message/' + chatGroup.id + '/' + chatGroup.groupType"
          :selected="selected === chatGroup.id"
          @click="() => (selected = chatGroup.id)"
          :reload-detail="true"
          @taphold="groupDetailSheet(chatGroup)"
        >
          <div class="item-media">
            <img
              v-if="chatGroup.avatar !== null"
              :src="`data:image/png;base64,${chatGroup.avatar}`"
              width="40"
              style="height: 48px; width: 48px; border-radius: 25px"
            />
            <f7-badge
              v-else
              color="auto"
              style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
            >
              {{ $filtersGetInitial.getInitial(chatGroup.name) }}</f7-badge
            >
          </div>
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title">{{ chatGroup.name }}</div>
              <div :class="'item-footer newMessageTime' + chatGroup.id">
                <span
                  v-if="
                    chatGroup.id === group.id && messageList && messageList.length > 0
                  "
                  >{{ $dayjs(messageList[0].sentAt).fromNow() }}</span
                >
                <span
                  v-else-if="
                    chatGroup.id !== group.id &&
                    chatGroup.groupMessageList &&
                    chatGroup.groupMessageList.length > 0
                  "
                  >{{ $dayjs(chatGroup.groupMessageList[0].sentAt).fromNow() }}</span
                >
              </div>
            </div>
            <div class="item-subtitle">
              <div :class="'text-container isTypingFalse' + chatGroup.id">
                <span
                  v-if="
                    chatGroup.id === group.id && messageList && messageList.length > 0
                  "
                >
                  <span v-if="messageList[0].senderId !== chatUser.userId"
                    >{{ getSenderFirstName(messageList[0].senderName) }}: </span
                  ><span v-html="getMessageIcon(messageList[0])"></span>
                </span>
                <span
                  v-else-if="
                    chatGroup.id !== group.id &&
                    chatGroup.groupMessageList &&
                    chatGroup.groupMessageList.length > 0
                  "
                >
                  <span v-if="chatGroup.groupMessageList[0].senderId !== chatUser.userId"
                    >{{
                      getSenderFirstName(chatGroup.groupMessageList[0].senderName)
                    }}: </span
                  ><span v-html="getMessageIcon(chatGroup.groupMessageList[0])"></span>
                </span>
              </div>
              <div
                :class="'text-container isTypingTrue' + chatGroup.id"
                style="display: none"
              ></div>
              <f7-badge
                v-if="
                  allGroupsUnReadMsg &&
                  allGroupsUnReadMsg.find((x) => x.id === chatGroup.id)
                "
                slot="media"
                color="auto"
                :class="'unReadBadge unreadMessageBadge' + chatGroup.id"
                >{{
                  allGroupsUnReadMsg.find((x) => x.id === chatGroup.id).length
                }}</f7-badge
              >
              <f7-link
                class="chat-menu-icon"
                popover-open=".groupDetailActionsPopover"
                @click="groupDetailPopover(chatGroup)"
                v-if="!f7.support.touch"
                icon-aurora="f7:chevron_down"
                slot="media"
                icon-ios="f7:chevron_down"
                icon-md="material:expand_more"
              >
              </f7-link>
            </div>
            <div
              :class="'targetIcon' + chatGroup.id"
              style="right: 8px; position: absolute"
            ></div>
          </div>
        </a>

        <ul v-if="chatGroup.subGroupList && chatGroup.subGroupList.length > 0">
          <li
            v-for="(chatSubGroup, index) in chatGroup.subGroupList"
            :key="index + '' + chatSubGroup.name"
          >
            <a
              v-if="
                chatSubGroup.subGroupParticipantList.find((x) => x.id === chatUser.id) !==
                null
              "
              :href="'/message/' + chatSubGroup.id + '/' + chatSubGroup.groupType"
              :selected="selected === chatSubGroup.id"
              @click="() => (selected = chatSubGroup.id)"
              :reload-detail="true"
              @taphold="groupDetailSheet(chatSubGroup)"
              class="item-link item-content actionsSheetTaphold"
            >
              <div class="item-media">
                <img
                  v-if="chatSubGroup.avatar !== null"
                  :src="`data:image/png;base64,${chatSubGroup.avatar}`"
                  style="height: 36px; width: 36px; border-radius: 25px"
                />
                <f7-badge
                  v-else
                  color="auto"
                  style="height: 36px; width: 36px; font-size: 12px; border-radius: 25px"
                >
                  {{ $filtersGetInitial.getInitial(chatSubGroup.name) }}</f7-badge
                >
              </div>
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">{{ chatSubGroup.name }}</div>
                  <div :class="'item-footer newMessageTime' + chatSubGroup.id">
                    <span
                      v-if="
                        chatSubGroup.id === group.id &&
                        messageList &&
                        messageList.length > 0
                      "
                      >{{ $dayjs(messageList[0].sentAt).fromNow() }}</span
                    >
                    <span
                      v-else-if="
                        chatSubGroup.id !== group.id &&
                        chatSubGroup.subGroupMessageList &&
                        chatSubGroup.subGroupMessageList.length > 0
                      "
                      >{{
                        $dayjs(chatSubGroup.subGroupMessageList[0].sentAt).fromNow()
                      }}</span
                    >
                  </div>
                </div>
                <div class="item-subtitle">
                  <div :class="'text-container isTypingFalse' + chatSubGroup.id">
                    <span
                      v-if="
                        chatSubGroup.id === group.id &&
                        messageList &&
                        messageList.length > 0
                      "
                    >
                      <span v-if="messageList[0].senderId !== chatUser.userId"
                        >{{ getSenderFirstName(messageList[0].senderName) }}:
                      </span>
                      <span v-html="getMessageIcon(messageList[0])"></span
                    ></span>
                    <span
                      v-else-if="
                        chatSubGroup.id !== group.id &&
                        chatSubGroup.subGroupMessageList &&
                        chatSubGroup.subGroupMessageList.length > 0
                      "
                    >
                      <span
                        v-if="
                          chatSubGroup.subGroupMessageList[0].senderId !== chatUser.userId
                        "
                        >{{
                          getSenderFirstName(
                            chatSubGroup.subGroupMessageList[0].senderName
                          )
                        }}: </span
                      ><span
                        v-html="getMessageIcon(chatSubGroup.subGroupMessageList[0])"
                      ></span>
                    </span>
                  </div>
                  <div
                    :class="'text-container isTypingTrue' + chatSubGroup.id"
                    style="display: none"
                  ></div>
                  <f7-badge
                    v-if="
                      allGroupsUnReadMsg &&
                      allGroupsUnReadMsg.find((x) => x.id === chatSubGroup.id)
                    "
                    slot="media"
                    color="auto"
                    :class="'unReadBadge unreadMessageBadge' + chatSubGroup.id"
                    >{{
                      allGroupsUnReadMsg.find((x) => x.id === chatSubGroup.id).length
                    }}</f7-badge
                  >
                  <f7-link
                    class="chat-menu-icon"
                    popover-open=".groupDetailActionsPopover"
                    @click="groupDetailPopover(chatSubGroup)"
                    v-if="!f7.support.touch"
                    icon-aurora="f7:chevron_down"
                    slot="media"
                    icon-ios="f7:chevron_down"
                    icon-md="material:expand_more"
                  >
                  </f7-link>
                </div>
                <div
                  :class="'targetIcon' + chatSubGroup.id"
                  style="right: 8px; position: absolute"
                ></div>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li v-if="chatGroup.groupType === 'PERSONAL'">
        <a
          :href="'/message/' + chatGroup.id + '/' + chatGroup.groupType"
          :selected="selected === chatGroup.id"
          @click="() => (selected = chatGroup.id)"
          :reload-detail="true"
          @taphold="groupDetailSheet(chatGroup)"
          class="item-link item-content actionsSheetTaphold"
        >
          <div class="item-media">
            <img
              v-if="
                chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub) !==
                  null &&
                chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                  .avatar !== null
              "
              :src="`data:image/png;base64,${
                chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                  .avatar
              }`"
              width="40"
              style="height: 48px; width: 48px; border-radius: 25px"
            />
            <f7-badge
              v-if="
                !chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                  .avatar &&
                chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                  .name !== null
              "
              color="auto"
              style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
            >
              {{
                $filtersGetInitial.getInitial(
                  chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                    .name
                )
              }}</f7-badge
            >
          </div>
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title">
                {{
                  chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                    .username
                }}
              </div>
              <div :class="'item-footer newMessageTime' + chatGroup.id">
                <span
                  v-if="
                    chatGroup.id === group.id && messageList && messageList.length > 0
                  "
                  >{{ $dayjs(messageList[0].sentAt).fromNow() }}</span
                >
                <span
                  v-else-if="
                    chatGroup.id !== group.id &&
                    chatGroup.groupMessageList &&
                    chatGroup.groupMessageList.length > 0
                  "
                  >{{ $dayjs(chatGroup.groupMessageList[0].sentAt).fromNow() }}</span
                >
              </div>
            </div>
            <div class="item-subtitle">
              <div :class="'text-container isTypingFalse' + chatGroup.id">
                <span
                  v-if="
                    chatGroup.id === group.id && messageList && messageList.length > 0
                  "
                  ><span v-if="messageList[0].senderId === chatUser.userId">
                    <f7-icon
                      ios="f7:checkmark_alt"
                      aurora="f7:checkmark_alt"
                      md="material:done"
                      v-if="messageList[0].status === 'sent'"
                      color="gray"
                      bg-color="white"
                      style="
                        border-radius: 50%;
                        font-size: 120%;
                        opacity: 1;
                        background-color: var(--f7-message-sent-text-color);
                      "
                    ></f7-icon>
                    <f7-icon
                      ios="f7:checkmark_circle"
                      aurora="f7:checkmark_circle"
                      md="material:done_all"
                      v-if="messageList[0].status === 'read'"
                      style="
                        border-radius: 50%;
                        font-size: 120%;
                        opacity: 1;
                        background-color: var(--f7-message-sent-text-color);
                      "
                      color="auto"
                    ></f7-icon>
                  </span>
                  <span v-html="getMessageIcon(messageList[0])"></span>
                </span>
                <span
                  v-else-if="
                    chatGroup.id !== group.id &&
                    chatGroup.groupMessageList &&
                    chatGroup.groupMessageList.length > 0
                  "
                >
                  <span v-if="chatGroup.groupMessageList[0].senderId === chatUser.userId">
                    <f7-icon
                      ios="f7:checkmark_alt"
                      aurora="f7:checkmark_alt"
                      md="material:done"
                      v-if="chatGroup.groupMessageList[0].status === 'sent'"
                      color="gray"
                      bg-color="white"
                      style="
                        border-radius: 50%;
                        font-size: 120%;
                        opacity: 1;
                        background-color: var(--f7-message-sent-text-color);
                      "
                    ></f7-icon>
                    <f7-icon
                      ios="f7:checkmark_circle"
                      aurora="f7:checkmark_circle"
                      md="material:done_all"
                      v-if="chatGroup.groupMessageList[0].status === 'read'"
                      style="
                        border-radius: 50%;
                        font-size: 120%;
                        opacity: 1;
                        background-color: var(--f7-message-sent-text-color);
                      "
                      color="auto"
                    ></f7-icon>
                  </span>
                  <span v-html="getMessageIcon(chatGroup.groupMessageList[0])"></span>
                </span>
              </div>
              <span
                :class="'text-container isTypingTrue' + chatGroup.id"
                style="display: none"
              ></span>
              <f7-badge
                v-if="
                  allGroupsUnReadMsg &&
                  allGroupsUnReadMsg.find((x) => x.id === chatGroup.id)
                "
                slot="media"
                color="auto"
                :class="'unReadBadge unreadMessageBadge' + chatGroup.id"
                >{{
                  allGroupsUnReadMsg.find((x) => x.id === chatGroup.id).count
                }}</f7-badge
              >
              <f7-link
                class="chat-menu-icon"
                popover-open=".groupDetailActionsPopover"
                @click="groupDetailPopover(chatGroup)"
                v-if="!f7.support.touch"
                icon-aurora="f7:chevron_down"
                slot="media"
                icon-ios="f7:chevron_down"
                icon-md="material:expand_more"
              >
              </f7-link>
            </div>
            <span
              :class="'targetIcon' + chatGroup.id"
              style="right: 8px; position: absolute"
            ></span>
          </div>
        </a>
      </li>
    </f7-list>

    <createGroupPanel :selectedGroup="this.selectedGroup" />
    <groupActions :selectedGp="this.selectedGroup" />
    <sendMsgPanel></sendMsgPanel>
    <template #fixed>
      <f7-fab
        v-if="this.isTablet == false && this.chatUser"
        position="right-bottom"
        @click="openSendMessageLeftPanel()"
      >
        <f7-icon
          ios="f7:ellipses_bubble_fill"
          aurora="f7:ellipses_bubble_fill"
          md="material:chat"
        ></f7-icon>
      </f7-fab>

      <!-- <f7-fab
        @click="joinAvochat()"
        v-if="!this.chatUser || this.chatUser === ''"
        position="center-bottom"
        :text="$t('tamtam.joinAvochat')"
      >
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        <div class="fab-text" v-html="$t('tamtam.joinAvochat')"></div>
      </f7-fab> -->
    </template>

    <div class="ptr-preloader">
      <div class="preloader"></div>
      <div class="ptr-arrow"></div>
    </div>
    <f7-popover class="otherActions">
      <f7-list dividers-ios strong-ios media-list no-chevron>
        <f7-list-item
          link="#"
          popover-close
          :title="$t('tamtam.newGroup')"
          @click="openCreateGroupeLeftPanel()"
        >
        </f7-list-item>
        <f7-list-item
          link="#"
          popover-close
          :title="$t('tamtam.newMessage')"
          @click="openSendMessageLeftPanel()"
        >
        </f7-list-item>
      </f7-list>
    </f7-popover>

    <div
      class="fab fab-extended fab-center-bottom color-red"
      @click="joinAvochat()"
      v-if="!this.chatUser || this.chatUser === ''"
    >
      <a href="#">
        <i class="icon f7-icons if-not-md">plus</i>
        <i class="icon material-icons md-only">add</i>
        <div class="fab-text" v-html="$t('tamtam.joinAvochat')"></div>
      </a>
    </div>
  </f7-page>
</template>
<style scoped>
.actionsSheetTaphold {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.chip-delete {
  color: red !important;
}

.unReadBadge {
  position: absolute;
  right: 0px;
  border-radius: 25px;
  width: var(--f7-tabbar-icon-size);
  height: var(--f7-tabbar-icon-size);
}

.tamtam_page .chat-menu-icon {
  display: none;
}

.tamtam_page .item-subtitle .text-container {
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 25px);
  text-overflow: ellipsis;
  display: inline-block;
}

.tamtam_page .item-link:hover .item-subtitle .text-container {
  max-width: calc(100% - 40px);
}

.tamtam_page .item-link:hover .item-subtitle .unReadBadge {
  right: 20px;
}

.tamtam_page .item-link:hover .chat-menu-icon {
  display: inline-block;
  position: absolute;
  right: 0px;
  color: gray;
}

.item-link {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (min-width: 768px) {
  a.link.icon-only.back {
    display: none;
  }
}

@media only screen and (min-width: 1025px) {
  .view-main {
    max-width: 100% !important;
  }

  /* .popup {
        max-width: 768px !important;
        margin: auto !important;
    } */
}

/* @media screen and (min-width: 1023px) {
  .view-master-detail .page-master {
    min-width: 60%;
  }
} */
</style>
<style>
.logoCropper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 769px) {
  .vue-advanced-cropper {
    max-height: 500px;
    position: relative;
    margin: 0 auto;
    max-width: 50%;
    /* width: 450px;
  left: 20%; */
  }

  .vertical-buttons {
    position: absolute;
    right: 2.5%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 768px) {
  .vue-advanced-cropper {
    max-height: 500px;
    position: relative;
    margin: 0 auto;
    width: 100%;
  }

  .vertical-buttons {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.removeAttachement {
  position: absolute;
  right: 16px;
  top: 8px;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Link,
  f7Searchbar,
  f7Subnavbar,
  f7BlockTitle,
  f7List,
  f7ListItem,
  f7ListGroup,
  f7Badge,
  f7Icon,
  f7Fab,
  f7Panel,
  f7Block,
  f7Chip,
  f7Toolbar,
  f7ListInput,
  f7Popover,
  f7Actions,
  f7ActionsButton,
  f7ActionsGroup,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import Navbarleft from "../components/navbarleft.vue";
import createGroupPanel from "../components/tamtam/createGpPanel.vue";
import groupActions from "../components/tamtam/groupActions.vue";
import sendMsgPanel from "../components/tamtam/sendMsgPanel.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { messageSocket } from "../js/websocket/websocketConnect.js";
import {
  messageOptionsMixin,
  messageGroupMixin,
  getusersMixin,
} from "../js/mixins/messageMixins.js";

// import { attachDocs } from "../js/mixins/attachDocs.js";
import { cordovaCamera } from "../js/mixins/cordovaCamera.js";
// import { messageConnect } from "../js/websocket/messageWebsocket.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    navbarleft: Navbarleft,
    createGroupPanel,
    groupActions,
    sendMsgPanel,
    Cropper,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Link,
    f7Searchbar,
    f7Subnavbar,
    f7List,
    f7ListItem,
    f7ListGroup,
    f7Badge,
    f7Icon,
    f7Fab,
    f7Panel,
    f7BlockTitle,
    f7Block,
    f7Chip,
    f7Toolbar,
    f7ListInput,
    f7Popover,
    f7Actions,
    f7ActionsButton,
    f7ActionsGroup,
  },
  mixins: [
    messageSocket,
    messageOptionsMixin,
    messageGroupMixin,
    getusersMixin,
    cordovaCamera,
    // attachDocs,
    // messageConnect,
  ],
  data() {
    return {
      theme,
      f7,
      // chatUsers: [],

      image: "",
      files: "",
      forms: "",
      selectedGroup: {},
      selected: "",
    };
  },

  mounted() {
    const self = this;
    f7.$(".view-main").css("width", f7.width);
    if (!this.msgCltConnected) {
      this.connectMessageSocket();
    }

    if (this.$keycloak.authenticated) {
      if (!this.chatUser) {
        self.$store.dispatch("message/getChatUser");
      }
      setTimeout(() => {
        if (!this.groupList) {
          self.$store.dispatch("message/getUserGroups");
        }
        if (!this.counterPartUserList) {
          self.$store.dispatch("message/getCounterPartUserList");
        }
      }, 300);
    }
    if (this.country === "" || !this.country) {
      self.$store.dispatch("location/getCountry");
    }
    if (this.isTablet) {
      f7.$(".tamtam_page .page").addClass("page-with-subnavbar");
      f7.$(".tamtam_page .page-content").addClass("hide-bars-on-scroll");
    }
    // if (this.msgCltConnected && this.groupList.length > 0) {
    //   this.connectMsgdService();
    // }
  },
  // unmounted() {
  //   f7.$(".mainToolbar").css("display", "block");
  // },
  methods: {
    ttTabshow() {
      f7.$(".mainToolbar").css("display", "none !important");
    },
    ttTabhide() {
      f7.$(".mainToolbar").css("display", "block !important");
    },
    joinAvochat() {
      const self = this;
      self.$store.dispatch("message/joinMessaging");
    },
    loadMore(done) {
      const self = this;
      setTimeout(() => {
        self.$store.dispatch("account/getAllUsers");
        if (this.chatUser) {
          self.$store.dispatch("message/getUserGroups");
        }
        done();
      }, 1000);
    },
    opensendMessageLeftPanel() {
      const self = this;
      f7.panel.toggle(".sendMessageLeftPanel", true);
    },
    groupDetailPopover(chatGroup) {
      const self = this;
      this.selectedGroup = chatGroup;
      console.log("groupDetailPopover", chatGroup);
      f7.popover.open(".groupDetailActionsPopover", `.targetIcon${chatGroup.id}`, true);
    },
    groupDetailSheet(chatGroup) {
      const self = this;
      this.selectedGroup = chatGroup;
      if (!this.isTablet) {
        console.log("groupDetailActionsSheet", chatGroup);
        f7.actions.open(".groupDetailActionsSheet", true);
      } else {
        console.log("groupDetailActionsPopover", chatGroup);
        f7.popover.open(".groupDetailActionsPopover", ".targetIcon" + chatGroup.id, true);
      }
    },

    // goToGroupChat(chatGroup) {
    //   const self = this;
    //
    //
    //   self.$store.dispatch("message/getGroupDetail", chatGroup.id);
    //   self.$store.dispatch("message/getGroupParticipantList", chatGroup.id);
    //   self.$store.dispatch("message/getUserGroupMessages", chatGroup.id);

    //   setTimeout(() => {
    //     this.f7router.navigate(
    //       "/message/" + chatGroup.id + "/" + chatGroup.groupType,
    //       {
    //         reloadDetail: true,
    //       }
    //     );
    //   }, 1000);
    // },
    getSenderFirstName(name) {
      return name.split(" ")[0];
    },
    getMessageIcon(message) {
      const self = this;
      if (message.msgType === "video") {
        return `<span >
        <i class="f7-  if-not-md size-22" style="color:var(--f7-tabbar-link-inactive-color); font-size: 100%;">videocam</i>
        <i class="material-  md-only size-22" style="color:var(--f7-tabbar-link-inactive-color); font-size: 100%;">videocam</i>
<span>${message.fileName || "video"}</span></span>`;
      } else if (message.msgType === "audio") {
        return `<span >
        <i class="f7-  if-not-md size-22" style="color:var(--f7-tabbar-link-inactive-color); font-size: 100%;">music_note_2</i>
        <i class="material-  md-only size-22" style="color:var(--f7-tabbar-link-inactive-color); font-size: 100%;">audiotrack</i>
<span>${message.fileName || "audio"}</span></span>`;
      } else if (message.msgType === "image") {
        return `<span >
        <i class="f7-  if-not-md size-22" style="color:var(--f7-tabbar-link-inactive-color); font-size: 100%;">photo</i>
        <i class="material-  md-only size-22" style="color:var(--f7-tabbar-link-inactive-color); font-size: 100%;">image</i>
<span>${message.fileName || "photo"}</span></span>`;
      } else if (message.msgType === "file") {
        return `<span >
        <i class="f7-  if-not-md size-22" style="color:var(--f7-tabbar-link-inactive-color); font-size: 100%;">doc_text</i>
        <i class="material-  md-only size-22" style="color:var(--f7-tabbar-link-inactive-color); font-size: 100%;">description</i>
<span>${message.fileName || "file"}</span></span>`;
      } else {
        return `<span >${message.text} </span>`;
      }
    },
  },

  computed: {
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
      "infoMessage",
      "messagesData",
      "groupUnReadMsg",
      "allGroupsUnReadMsg",
      "messageSocketClient",
      "msgCltConnected",
    ]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "searchedUser", "users"]),

    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    // ...mapGetters("message", ["messagesData"]),
  },
};
</script>
