<template>
  <f7-page class="accountDetail_page">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false">
      </f7-nav-left>
      <f7-nav-title v-if="this.activeAccount">{{ this.activeAccount.tag }}</f7-nav-title>
      <f7-nav-right>
        <f7-link v-if="this.activeAccount && this.activeAccount.kyc_level !== 'LEVEL_0'" icon-color="red"
          icon-ios="f7:text_badge_plus" icon-md="material:playlist_add" color="red" href="#"
          popup-open=".addNewApiWallet"></f7-link>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content" style="padding-bottom: 100px !important">
      <div class="left_layout"></div>
      <div class="main_layout">
        <templatePreloader v-if="!this.activeAccount"></templatePreloader>

        <f7-card v-else outline header-divider :class="this.activeAccount && this.activeAccount.status !== 'ACTIVE'
            ? 'border-color-red highlighted'
            : ''
          ">
          <f7-card-header class="grid grid-rows-2" v-if="this.activeAccount"><span>{{ this.activeAccount.tag }}</span>
            <span style="font-size: small" v-if="this.activeAccount.type !== ''">{{
              getAccountType(this.activeAccount.type) }} №:
              {{ this.activeAccount.id }}</span></f7-card-header>

          <f7-card-content v-if="this.activeAccount">
            <h4>
              {{ $t("common.status") }}:
              <f7-badge :color="this.activeAccount.status === 'ACTIVE' ? 'primary' : 'deeporange'">
                {{ getAccountStatus(this.activeAccount.status) }}</f7-badge>
            </h4>

            <f7-list strong inset media-list v-if="this.mainWallet">
              <f7-list-item>{{ $t("common.accountMainWallet") }}</f7-list-item>
              <li>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title">{{ mainWallet.tag }}</div>

                      <div class="item-after">№: {{ mainWallet.id }}</div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-title">{{ $t("common.balance") }}</div>

                      <div class="item-after">
                        {{
                          $filtersToCurrency.toCurrency(
                            this.mainWallet.balance,
                            null,
                            this.mainWallet.currency
                          )
                        }}
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-title">{{ $t("common.availaible_balance") }}</div>

                      <div class="item-after">
                        {{
                          $filtersToCurrency.toCurrency(
                            this.mainWallet.balance,
                            null,
                            this.mainWallet.currency
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <f7-list-item v-if="this.accountWallets && this.accountWallets.length > 0"
                @smartselect:closed="setAccountMainWallet()" :title="$t('common.assignOrChangeMainWallet')" smart-select
                :smart-select-params="{
                  openIn: this.isTablet ? 'popover' : 'sheet',
                  closeOnSelect: true,
                }">
                <select name="main_wallet" v-model="currentMainWallet">
                  <optgroup :label="this.activeAccount.tag">
                    <option v-for="wallet in this.accountWallets" :value="wallet.id"
                      :selected="wallet.id == mainWallet.id"
                      :data-option-color="wallet.id == mainWallet.id ? 'primary' : ''">
                      {{ wallet.tag }} №:{{ wallet.id }}
                    </option>
                  </optgroup>
                </select>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
          <f7-card-content>
            <kyclevel v-if="this.activeAccount" :activeAccount="this.activeAccount"></kyclevel>
          </f7-card-content>
        </f7-card>

        <f7-block-title large v-if="this.accountWallets && this.accountWallets.length > 0">
          {{ $t("common.accountwallets") }}</f7-block-title>
        <div class="grid xsmall-grid-cols-1 small-grid-cols-2">
          <a v-for="(wallet, index) in this.accountWallets" :key="index"
            :href="`/account/${activeAccount.id}/wallet/${wallet.id}`">
            <f7-card grid outline header-divider style="height: 250px" :class="wallet.main
                ? 'border-color-primary highlighted'
                : wallet.shared
                  ? 'border-color-orange highlighted'
                  : 'border-color-blue highlighted'
              ">
              <f7-link :id="`wallet_${wallet.id}`" @click="openWalletMenu(wallet)" icon-color="primary" icon-ios="f7:menu"
                icon-md="material:menu" style="position: absolute; right: 4px; top: 4px"></f7-link>
              <f7-card-header class="grid grid-rows-2" style="height: 100px">
                <span style="font-size: large">{{ wallet.tag }}</span><span style="font-size: small">№: {{ wallet.id
                }}</span>
              </f7-card-header>
              <f7-card-content class="grid grid-rows-2">
                <span>
                  {{ $t("common.balance")
                  }}<span style="font-size: x-large; font-weight: 500">{{
  $filtersToCurrency.toCurrency(wallet.balance, null, wallet.currency)
}}</span></span>
                <span>
                  {{ $t("common.availaible_balance") }}
                  <span style="font-size: large; font-weight: 300">{{
                    $filtersToCurrency.toCurrency(
                      wallet.balance_available,
                      null,
                      wallet.currency
                    )
                  }}</span></span>
              </f7-card-content>
            </f7-card>
          </a>
        </div>
      </div>
      <div class="right_layout"></div>
    </div>
    <!-- && this.activeAccount.kyc_level !== 'LEVEL_0' -->
    <f7-fab v-if="this.activeAccount" position="center-bottom" :text="$t('account.addNewApiWallet')" color="red" href="#"
      popup-open=".addNewApiWallet" @click="openPopuNewWallet()">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>
    <f7-popup class="addNewApiWallet" style="--f7-sheet-bg-color: #fff" swipe-to-close :tablet-fullscreen="true" :params="{
      backdrop: true,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }"><f7-view>
        <f7-page>
          <f7-navbar>
            <div class="left"></div>
            <div class="title">
              {{ $t("account.addNewApiWallet") }}
            </div>
            <div class="right">
              <f7-link popup-close icon-f7="xmark_circle"></f7-link>
            </div>
          </f7-navbar>
          <div class="layout_content">
            <div class="left_layout"></div>
            <div class="main_layout">
              <f7-block-title large>
                {{ $t("account.addNewApiWalletToAccount") }}</f7-block-title>
              <f7-block>
                <f7-list dividers-ios strong-ios media-list>
                  <f7-list-item v-if="this.activeAccount" :title="this.activeAccount.type"
                    :after="'№:' + this.activeAccount.id" />

                  <f7-list-item :header="$t('common.selectCountry')" class="selectCountry" smart-select
                    :smart-select-params="{
                      openIn: !isTablet || this.f7.device.cordova ? 'popup' : 'page',
                      searchbar: true,
                      searchbarPlaceholder: $t('common.findCountry'),
                      closeOnSelect: true,
                    }">
                    <template #subtitle v-if="this.userCtry && this.walletctry">
                      <img :src="'https://flagcdn.com/' + this.userCtry.toLowerCase() + '.svg'
                        " width="24" />
                      {{ this.walletctry.name }} {{ this.walletctry.currencyCode }}
                      {{ this.walletctry.currencyName }}
                    </template>
                    <select name="walletCountry" v-model="this.userCtry" required validate>
                      <optgroup class="list-group" v-for="(countryList, continentName, index) in this.countries"
                        :label="continentName" :key="index">
                        <option v-for="country in countryList" :key="country.id" :value="country.countryCode2"
                          :selected="country.countryCode2 === userCtry" :data-option-image="'https://flagcdn.com/' +
                            country.countryCode2.toLowerCase() +
                            '.svg'
                            " width="24">
                          {{ country.name }} {{ country.currencyCode }}
                          {{ country.currencyName }}
                        </option>
                      </optgroup>
                    </select>
                  </f7-list-item>

                  <f7-list-input :label="$t('common.tag')" floating-label :info="$t('common.tagInfo')"
                    :error-message="$t('common.tagInfo')" :value="this.tag" name="tag"
                    @input="this.tag = $event.target.value" resizable required validate maxlength="80" type="text" />
                </f7-list>

                <f7-button fill large rippled color="red" strong @click="addApiWallet()">{{ $t("account.addNewApiWallet")
                }}</f7-button>
              </f7-block>
            </div>
            <div class="right_layout"></div>
          </div>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
.mainToolbar {
  display: none !important;
}
</style>
<script>
import {
  f7List,
  f7ListGroup,
  f7BlockTitle,
  f7Accordion,
  f7BlockHeader,
  f7ListItem,
  f7ListInput,
  f7Button,
  theme,
  f7,
} from "framework7-vue";
import { mapState, mapGetters } from "vuex";
import Navbarright from "../components/navbarright.vue";
import kyclevel from "../components/api_money/kyc_level.vue";
import templatePreloader from "../components/template_preloader.vue";
import { account } from "../js/mixins/account.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [account],
  components: {
    f7List,
    f7ListGroup,
    f7BlockTitle,
    f7Accordion,
    f7BlockHeader,
    f7ListItem,
    f7ListInput,
    f7Button,
    navbarright: Navbarright,
    kyclevel,
    templatePreloader,
  },
  data() {
    return {
      theme,
      f7,
      tag: "",
      accountStatus: "",
      accountIsActive: "",
      currentMainWallet: "",
      userCtry: "",
    };
  },
  mounted() {
    this.userCtry = this.userAccount.countryCode || this.profile.countryCode;
    if (this.userAccount === null && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.mainWallet) {
      this.currentMainWallet = this.mainWallet.id;
    }
    f7.$(".mainToolbar").css({ display: "none" });
  },

  methods: {
    openPopuNewWallet() {
      f7.popup.open(".addNewApiWallet");
    },
  },
  computed: {
    ...mapState("apimoney", [
      "bankAccount",
      "bankAccountList",
      "standard",
      "business",
      "accountList",
    ]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "documentsUrl", "document"]),
    ...mapState("account", ["userAccount"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    activeAccount() {
      console.log(" this.f7route.params", this.f7route.params);
      // if (this.userAccount && this.f7route.params.type) {
      return this.f7route.params.type && this.f7route.params.type === "standard"
        ? this.userAccount.standard
        : this.userAccount.business;
      // }
    },

    accountWallets() {
      if (this.userAccount.userAccountWallets) {
        const walletType =
          this.f7route.params.type === "standard" ? "STANDARD" : "BUSINESS";
        const wallets = this.userAccount.userAccountWallets[walletType];
        if (wallets && wallets.length > 0) {
          return wallets;
        }
      }
    },

    mainWallet() {
      if (this.userAccount.userAccountWallets) {
        return this.f7route.params.type === "standard"
          ? this.userAccount.standardWallet
          : this.userAccount.businessWallet;
      }
    },
    countries() {
      return _.groupBy(this.country, "continentName");
    },
    walletctry() {
      if (this.userCtry) {
        return this.country.find((x) => x.countryCode2 === this.userCtry);
      }
    },
  },
};
</script>
