// import base_url from "../base_url.js";
import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "savings";
import _ from "lodash";
import { f7 } from "framework7-vue";

//   const savings={
export default (app) => {
  return {
    namespaced: true,
    state: {
      savings: "",
      battle: "",
      participant: "",
      participantList: "",
      admin: "",
      savingsTransaction: "",
      savingsList: "",
      activeassociantionList: "",
      adminList: "",
      qrCode: "",
    },
    getters: {},
    mutations: {
      CREATE_SAVINGS(state, savings) {
        state.savings = savings;
      },
      GET_SAVINGS(state, savings) {
        state.savings = savings;
      },

      EDIT_SAVINGS(state, savings) {
        state.savings = savings;
      },
      GET_SAVINGSLIST(state, savingsList) {
        state.savingsList = savingsList;
      },
      GET_SAVINGSTRANSACTION(state, savingsTransaction) {
        state.savingsTransaction = savingsTransaction;
      },

      GET_SAVINGSTRANSACTION_LIST(state, savingsTransactionList) {
        state.savingsTransactionList = savingsTransactionList;
      },
    },

    actions: {
      createSavings({ commit }, payload) {
        console.log(payload);
        axios({
          url: path,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("CREATE_SAVINGS", savings);
            f7.views.main.router.navigate(`/saving/${savings.id}`);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getSavings({ commit }, payload) {
        axios({
          url: path,
          method: "GET",
          params: { savingsGroupId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            console.log("savings", savings);
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserSavingsGroupList({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/user`,
          method: "GET",
          params: {
            // userId: payload.userId,
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savingsList) => {
            console.log("savingsList", savingsList);
            commit("GET_SAVINGSLIST", savingsList);
          })
          .catch((err) => {
            console.log(err);
            commit("GET_SAVINGSLIST", []);
          });
      },
      editSavings({ commit }, payload) {
        console.log(payload);
        axios({
          url: path,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("EDIT_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      createBattle({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/battle`,
          method: "POST",
          data: payload.battle,
          params: {
            savingsGroupId: payload.savingsGroupId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      getBattle({ commit }, payload) {
        axios({
          url: `${path}/battle`,
          method: "GET",
          params: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((battle) => {
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editBattle({ commit }, payload) {
        axios({
          url: `${path}/battle`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      joinBattle({ commit }, payload) {
        console.log("payload", payload);
        axios({
          url: `${path}/battle/join`,
          method: "POST",
          params: {
            battleId: payload.battleId,
          },
          data: payload.newParticipants,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            console.log("savings", savings);
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      leaveBattle({ commit }, payload) {
        axios({
          url: `${path}/battle/leave`,
          method: "PUT",
          params: {
            participantId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      getSavingParticipant({ commit }, payload) {
        axios({
          url: `${path}/battle/participant`,
          method: "GET",
          params: {
            participantId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      addCampaignManager({ commit }, payload) {
        axios({
          url: `${path}/add/manager`,
          method: "PUT",
          data: payload,
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },

      addParticipant({ commit }, payload) {
        axios({
          url: `${path}/add/participant`,
          method: "POST",
          params: {
            userId: payload.userId,
            savingsGroupId: payload.savingsGroupId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      removeParticipant({ commit }, payload) {
        axios({
          url: `${path}/remove/participant`,
          method: "PUT",
          params: {
            userId: payload.userId,
            savingsGroupId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("GET_SAVINGS", savings);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getSavingsTransactionList({ commit }, payload) {
        axios({
          url: `${path}/transactions`,
          method: "GET",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            savingsGroupId: payload.savingsGroupId,
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savingsTransactionList) => {
            commit("GET_SAVINGSTRANSACTION_LIST", savingsTransactionList);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      grantAdminRole({ commit }, payload) {
        axios({
          url: `${path}/grant/admin`,
          method: "POST",
          params: {
            userId: payload.userId,
            savingsGroupId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("GET_SAVINGS", savings);
            console.log(savings);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      withdrawAdminRole({ commit }, payload) {
        axios({
          url: `${path}/remove/admin`,
          method: "PUT",
          params: {
            userId: payload.userId,
            savingsGroupId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((savings) => {
            commit("GET_SAVINGS", savings);
            console.log(savings);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
};
// export default savings;
