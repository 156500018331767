<template>
  <f7-page :page-content="false" hide-bars-on-scroll class="userProfilePage">
    <f7-navbar v-if="this.profile" no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false"
      :title="this.profile.name">
      <f7-nav-right>
        <f7-nav-right>
          <navbarright></navbarright>
        </f7-nav-right>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar top no-shadow>
      <f7-link icon-aurora="f7:person_circle_fill" icon-ios="f7:person_circle_fill" icon-md="material:account_box"
        :text="$t('profile.myDetails')" tab-link="#myDetails" tab-link-active style="text-transform: lowercase">
      </f7-link>
      <f7-link icon-aurora="f7:tray_2_fill" icon-ios="f7:tray_2_fill" icon-md="material:account_balance_wallet"
        :text="$t('common.primaryAccount')" tab-link="#standardAccount" style="text-transform: lowercase"></f7-link>
      <f7-link icon-aurora="f7:square_stack_3d_up_fill" icon-ios="f7:square_stack_3d_up_fill"
        icon-md="material:account_balance" :text="$t('common.businessAccount')" tab-link="#businessAccount"
        style="text-transform: lowercase"></f7-link>
    </f7-toolbar>

    <f7-tabs>
      <f7-tab id="myDetails" tab-active class="page-content" style="padding-bottom: 50px !important">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-list list media-list strong-ios dividers-ios id="userform1" v-if="this.profile">
              <f7-block-title class="padding-left">{{
                $t("profile.myPersonalInfo")
              }}</f7-block-title>
              <f7-link @click="editProfile()" :style="{ position: 'absolute', right: '16px', top: '-8px' }"
                icon-aurora="f7:pencil" icon-ios="f7:pencil" icon-md="material:edit"></f7-link>
              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row" style="font-size: 75%; color: gray">
                    <div class="item-title">{{ $t("common.firstName") }}</div>
                    <div class="item-title">{{ $t("common.lastName") }}</div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title">{{ profile.given_name }}</div>
                    <div class="item-title">{{ profile.family_name }}</div>
                  </div>
                </div>
              </li>
              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row" style="font-size: 75%; color: gray">
                    <div class="item-title">{{ $t("common.dateOfBirth") }}</div>
                    <div class="item-title">
                      {{ $t("profile.myAvocashAccountStatus") }}
                    </div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title">{{ profile.dateOfBirth }}</div>
                    <div v-if="profile.email_verified == true" class="item-title">
                      {{ $t("profile.verified") }}
                    </div>
                    <div v-if="profile.email_verified == false" class="item-title">
                      {{ $t("profile.pendingVerification") }}
                    </div>
                  </div>
                </div>
              </li>
              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row" style="font-size: 75%; color: gray">
                    <div class="item-title">{{ $t("common.phoneNumber") }}</div>
                    <div class="item-title">{{ $t("common.email") }}</div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title">
                      +{{ this.profile.phoneCode }}
                      {{ this.profile.phoneNumber }}
                    </div>
                    <div class="item-title">{{ profile.email }}</div>
                  </div>
                </div>
              </li>

              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row" style="font-size: 75%; color: gray">
                    <div class="item-title">{{ $t("common.country") }}</div>
                    <div class="item-title">{{ $t("common.locality") }}</div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title">{{ profile.address.country }}</div>
                    <div class="item-title">{{ profile.address.locality }}</div>
                  </div>
                </div>
              </li>
              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row" style="font-size: 75%; color: gray">
                    <div class="item-title">{{ $t("common.address") }}</div>
                    <div class="item-title">{{ $t("common.postCode") }}</div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title">
                      {{ profile.address.street_address }}
                    </div>
                    <div class="item-title">
                      {{ profile.address.postal_code }}
                    </div>
                  </div>
                </div>
              </li>

              <!-- <f7-list-item link="#" after="Add" :title="$t('profile.uploadProofOfIdentity')"
            panel-open=".uploadProofOfIdOpen" v-if="this.primaryAccount.proofOfId === null">
          </f7-list-item>
          <f7-list-item panel-open=".uploadProofOfIdOpen" link="#" v-if="
            this.primaryAccount.proofOfId !== null && this.primaryAccount.expiryDate
          " header="Proof of identity" :title="this.primaryAccount.proofOfId" :subtitle="
  'Expiry date: ' +
  (this.primaryAccount.expiryDate || new Date().toString()).substr(0, 10)
" after="Update"></f7-list-item>
          <f7-list-item v-if="
            new Date() > this.primaryAccount.expiryDate &&
            this.primaryAccount.proofOfId !== null
          " link="#" after="Update" header="Proof of identity" :title="this.primaryAccount.proofOfId + ' expired'"
            :subtitle="
              'Expiry date: ' +
              (this.primaryAccount.expiryDate || new Date().toString()).substr(0, 10)
            " popup-open=".uploadAvatarOpen">
          </f7-list-item> -->
              <f7-list-item @click="attachFileOpenActions()" link="#" after="Add" class="attachFileButton"
                :title="$t('profile.uploadProfilePicture')" v-if="this.userAccount.avatar === null &&
                  this.documentsUrl &&
                  this.documentsUrl.length == 0
                  ">
              </f7-list-item>
              <f7-list-item v-if="this.userAccount.avatar !== null" link="#" :title="$t('profile.uploadProfilePicture')"
                @click="attachFileOpenActions()" class="attachFileButton" after="Update"><template #media>
                  <img :src="`data:image/png;base64,${this.userAccount.avatar}`" v-if="!!this.userAccount.avatar" style="
                      width: 48px;
                      height: 48px;
                      background-size: cover;
                      display: block;
                      border-radius: 50%;
                    " alt="user avatar" /></template>
              </f7-list-item>

              <f7-list-group v-show="this.avatar == true">
                <span v-for="(docItem, index) in this.documentsUrl" :key="index">
                  <f7-list-item :footer="docItem.name" link="#" v-if="docItem.fileType === 'image' && docItem.url">
                    <template #media>
                      <img :src="docItem.url" v-if="docItem.fileType === 'image'" style="
                          width: 48px;
                          height: 48px;
                          background-size: cover;
                          display: block;
                          border-radius: 50%;
                        " alt="user avatar" /></template>
                    <template #after>
                      <f7-link v-if="docItem.fileType === 'image'" raised :style="{
                        right: '60px',
                      }" icon-f7=" pencil_circle" @click="editImage(docItem.id)"></f7-link>
                      <f7-link @click="removeDoc(index)" :style="{}" raised color="red"
                        icon-f7="multiply_circle_fill"></f7-link>
                    </template>
                  </f7-list-item>
                </span>



                <f7-button v-if="this.documentsUrl && this.documentsUrl.length > 0" raised fill ripple large color="red"
                  class="margin-top margin-horizontal buttons" @click="uploadAvatarLogo(userAccount.standard)">{{
                    $t("profile.saveProfilePicture") }}</f7-button>
              </f7-list-group>
            </f7-list>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-tab>
      <f7-tab id="standardAccount" class="page-content" style="padding-bottom: 50px !important">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-list dividers-ios v-if="this.userAccount && this.userAccount.standard !== ''">
              <f7-block-title class="padding-left">{{
                $t("common.primaryAccount")
              }}</f7-block-title>

              <f7-list-item v-if="this.userAccount.standard !== null" :header="$t('common.account')"
                :title="this.userAccount.standard.id"></f7-list-item>
              <f7-list-item v-if="this.userAccount.standard.status !== 'ACTIVE'">
                <span>{{ $t("profile.accountIsNotActive") }}</span>
                <f7-toggle class="activeStatus" :disabled="this.userAccount.standard.status !== 'ACTIVE'"
                  color="auto"></f7-toggle>
              </f7-list-item>
              <f7-list-item>
                <span>{{ this.stdAcctPublicStatus }}</span>
                <f7-toggle class="publicStatus" :checked="this.userAccount.stdIsPublic == true" color="auto"
                  @change="publicStatusChange(userAccount.standard)"></f7-toggle>
              </f7-list-item>
              <f7-block-footer style="white-space: normal; word-wrap: break-word">{{
                $t("profile.publicAccountCan")
              }}</f7-block-footer>
            </f7-list>
            <f7-list dividers-ios strong-ios simple-list>
              <f7-block-title class="display-flex justify-content-space-between margin">{{ $t("profile.paymentLimit")
              }}<span v-html="$filtersToCurrency.toCurrency(
    this.paymentLimit,
    null,
    this.userAccount.currencyCode
  )
  "></span></f7-block-title>
              <f7-list-item>
                <div>
                  <f7-badge>{{ this.userAccount.currencyCode }}</f7-badge>
                </div>
                <div style="width: 100%; margin: 0 16px">
                  <f7-range :min="0" :max="100" :step="5" :scale="true" :scale-steps="5" :value="paymentLimit"
                    :label="true" @range:change="onPaymentLimitChange"></f7-range>
                </div>
                <div>
                  <f7-badge color="auto">{{ this.userAccount.currencyCode }}</f7-badge>
                </div>
              </f7-list-item>
            </f7-list>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-tab>
      <f7-tab id="businessAccount" class="page-content" style="padding-bottom: 50px !important">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <div class="business_account_info" v-if="this.userAccount && this.userAccount.business !== null">
              <f7-block-title style="white-space: normal; word-wrap: break-word" v-if="this.userAccount &&
                this.userAccount.business !== null &&
                this.userAccount.business.status !== 'ACTIVE'
                ">
                {{ $t("profile.businessAccountIs") }}<br />{{
                  $t("profile.pleaseProvideBusiness")
                }}</f7-block-title>
              <f7-button v-if="this.userAccount.business !== null &&
                  this.userAccount.business.status !== 'ACTIVE'
                  " href="/account/business/activate" class="margin buttons" fill raised color="red" large>{{
      $t("businessacceptcash.activateMyBusinessAccount") }}</f7-button>
              <f7-list media-list strong-ios dividers-ios v-if="this.userAccount.business !== null">
                <f7-block-title class="padding-left">{{
                  $t("common.businessAccount")
                }}</f7-block-title>
                <f7-link href="/account/business/edit" :style="{ position: 'absolute', right: '16px', top: '-8px' }"
                  icon-aurora="f7:pencil" icon-ios="f7:pencil" icon-md="material:edit"></f7-link>
                <f7-list-item v-if="this.userAccount.business !== null" :header="$t('common.account')"
                  :title="this.userAccount.business.id"></f7-list-item>

                <f7-list-item>
                  <span>{{ this.businessAcctPublicStatus }}</span>
                  <f7-toggle class="publicStatus" checked color="auto" @change="publicStatusChange(userAccount.business)">
                  </f7-toggle>
                </f7-list-item>
                <f7-list-item :header="$t('businessaccountactivate.myCompanyName')"
                  :title="this.userAccount.business.business_info.name">
                </f7-list-item>
                <f7-list-item :header="$t('businessaccountactivate.myCompanyNumber')"
                  :title="this.userAccount.business.business_info.registration_number"></f7-list-item>
                <f7-list-item :header="$t('businessaccountactivate.companyActivitydescription')"
                  :title="this.userAccount.business.business_info.description">
                </f7-list-item>
                <!-- <f7-list-item :header="$t('businessaccountactivate.myRoleIn')"
            :title="this.businessAccount.legalRepresentativeRole"></f7-list-item> -->
                <f7-list-item :header="$t('businessaccountactivate.companyPhoneNumber')"
                  :title="'+' + this.userAccount.business.business_info.phone_number"></f7-list-item>
                <f7-list-item :header="$t('businessaccountactivate.myCompanyEmail')"
                  :title="this.userAccount.business.business_info.email"></f7-list-item>
                <f7-list-item :header="$t('businessaccountactivate.website')"
                  :title="this.userAccount.business.business_info.website"></f7-list-item>
                <f7-list-item v-if="this.userAccount.business !== null" :header="$t('businessaccountactivate.street')"
                  :title="this.userAccount.business.address.label1 +
                    ' ' +
                    this.userAccount.business.address.label2
                    ">
                </f7-list-item>
                <f7-list-item v-if="this.userAccount.business.address !== null"
                  :header="$t('common.postCode') + ' ' + $t('common.citylocality')" :title="this.userAccount.business.address.zip_code +
                    ' ' +
                    this.userAccount.business.address.city
                    ">
                </f7-list-item>
                <f7-list-item @click="attachFileOpenActions()" link="#" class="attachFileButton"
                  :title="$t('profile.uploadLogo')" v-if="this.userAccount.logo === null &&
                    this.documentsUrl &&
                    this.documentsUrl.length == 0
                    ">
                </f7-list-item>
                <f7-list-item @click="attachFileOpenActions()" class="attachFileButton"
                  v-if="this.userAccount.logo !== null" link="#" :title="$t('profile.uploadNewLogo')"><template #media>
                    <img :src="`data:image/png;base64,${this.userAccount.logo}`" v-if="!!this.userAccount.logo" style="
                        width: 48px;
                        height: 48px;
                        background-size: cover;
                        display: block;
                      " alt="business logo" /></template>
                </f7-list-item>

                <f7-list-group v-if="this.avatar == false">
                  <span v-for="(docItem, index) in this.documentsUrl" :key="index">
                    <f7-list-item :footer="docItem.name" link="#" v-if="docItem.fileType === 'image' && docItem.url">
                      <template #media>
                        <img :src="docItem.url" v-if="docItem.fileType === 'image'" style="
                            width: 48px;
                            height: 48px;
                            background-size: cover;
                            display: block;
                            border-radius: 50%;
                          " alt="user avatar" /></template>
                      <template #after>
                        <f7-link v-if="docItem.fileType === 'image'" raised :style="{
                          right: '60px',
                        }" icon-f7=" pencil_circle" @click="editImage(docItem.id)"></f7-link>
                        <f7-link @click="removeDoc(index)" :style="{}" raised color="red"
                          icon-f7="multiply_circle_fill"></f7-link>
                      </template>
                    </f7-list-item>
                  </span>

                  <f7-button v-if="this.documentsUrl && this.documentsUrl.length > 0" raised fill ripple large color="red"
                    class="margin-top margin-horizontal buttons" @click="uploadAvatarLogo(userAccount.business)">{{
                      $t("common.save") }}
                  </f7-button>
                </f7-list-group>
              </f7-list>
            </div>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-tab>
    </f7-tabs>
    <editDocs></editDocs>
    <!-- <f7-panel
      right
      resizable
      cover
      @panel:opened="reducePageWidth"
      @panel:close="resetPageWidth"
      class="uploadProofOfIdOpen"
      :style="{
        'min-width': f7.width >= 768 ? '418px' : '100%',
        'border-left':
          'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
      }"
    >
      <f7-page page-with-subnavbar>
        <f7-navbar no-shadow  >
          <f7-nav-left>
            <f7-link panel-close>
              <f7-icon
                ios="f7:multiply"
                aurora="f7:multiply"
                md="material:close"
              ></f7-icon>
            </f7-link>
          </f7-nav-left>
          <div class="title">{{ $t("profile.uploadProofOfIdentity") }}</div>
        </f7-navbar>
        <f7-block-title medium class="margin-top padding-top">{{
          $t("profile.uploadProofOfIdentity")
        }}</f7-block-title>
        <f7-list dividers-ios strong-ios >
          <f7-list-input
            :label="$t('profile.proofOfIdentity')"
            type="select"
            :placeholder="$t('profile.selectProofOfIdentity')"
            @input="proofOfId = $event.target.value"
          >
            <option
              v-for="(idProof, index) in this.proofOfIdList"
              :value="idProof"
              :key="index"
            >
              {{ idProof }}
            </option>
          </f7-list-input>
          <f7-list-input
            :label="$t('common.expiryDate')"
            type="datepicker"
            :placeholder="$t('common.selectExpiryDate')"
            readonly
            required
            validate
            :disabled="this.proofOfId === ''"
            :value="expiryDate"
            @calendar:change="(value) => (expiryDate = value)"
            :calendar-params="{
              openIn: 'auto',
              minDate: new Date(),
              closeOnSelect: true,
              header: true,
              closeOnSelect: true,
            }"
            error-message="Please select proof of identity expiry date!"
            clear-button
          ></f7-list-input>
          <f7-list-input
            type="file"
            class="proofOfIninput"
            style="display: none"
            input-id="proofOfIninput"
            accept=".pdf, image/*, .doc, .docx"
            :value="documents"
            @input="documents = $event.target.value"
            multiple
            @change="uploadIdProof($event)"
          ></f7-list-input>
          <f7-link
            class="margin-left margin-top"
            @click="getPicturesIdProof()"
            icon-size="50"
            icon-ios="f7:photo_on_rectangle"
            icon-aurora="f7:photo_on_rectangle"
            icon-md="material:add_to_photos"
            :text="$t('profile.uploadProofOfIdentity')"
          ></f7-link>
          <f7-row no-gap v-for="(docItem, index) in this.documentsUrl" :key="index">
            <f7-col width="90">
              <f7-link
                :text="docItem.name"
                href="#"
                popup-open=".viewDocs"
                class="margin-left"
              >
              </f7-link>
            </f7-col>
            <f7-col width="10">
              <f7-link
                raised
                color="red"
                icon-f7="multiply_circle_fill"
                @click="removeDoc(index)"
              ></f7-link>
            </f7-col>
          </f7-row>
        </f7-list>
        <f7-toolbar tabbar bottom     bg-color="red">
          <f7-link
            raised
            fill
            class="link"
            large
            text-color="white"
            strong
            @click="uploadProofOfId()"
            >{{ $t("profile.uploadProofOfIdentity") }}</f7-link
          >
        </f7-toolbar>

      </f7-page>
    </f7-panel> -->
  </f7-page>
</template>
<style scoped>
html.with-panel-right-cover .panel-backdrop-in {
  visibility: none;
  pointer-events: auto;
  opacity: 0;
}
</style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7Toolbar,
  f7Link,
  f7Tabs,
  f7Tab,
  f7List,
  f7ListItem,
  f7BlockTitle,
  f7ListGroup,
  f7Button,
  f7Toggle,
  f7Badge,
  f7Range,
  f7Panel,
  theme,
  f7,
} from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import { attachDocs } from "../js/mixins/attachDocs.js";
import { cordovaCamera } from "../js/mixins/cordovaCamera.js";
import editDocs from "../components/editDocs.vue";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    editDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7Toolbar,
    f7Link,
    f7Tabs,
    f7Tab,
    f7List,
    f7ListItem,
    f7BlockTitle,
    f7ListGroup,
    f7Button,
    f7Toggle,
    f7Badge,
    f7Range,
    f7Panel,
  },
  mixins: [attachDocs, cordovaCamera],
  data() {
    return {
      theme,
      f7,
      proofOfIdList: [
        this.$t("profile.idCard"),
        this.$t("profile.passport"),
        this.$t("profile.residencePermit"),
        this.$t("profile.drivingLicence"),
      ],
      expiryDate: "",
      proofOfId: this.$t("profile.idCard"),
      documents: [],
      files: "",
      paymentLimit: 5,
      stdAcctPublicStatus: this.$t("profile.accountIsPublic"),
      businessAcctPublicStatus: this.$t("profile.accountIsPublic"),
      proofOfIdInputvalue: "",
      avatar: true,
      fileInput: "",
      forms: "",
    };
  },
  mounted() {
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }

    if (
      this.userAccount.stdPaymentLimit != this.paymentLimit ||
      this.userAccount.stdPaymentLimit != 0
    ) {
      this.paymentLimit = this.userAccount.stdPaymentLimit;
    }
    this.proofOfIdInputvalue = f7.$("#proofOfIninput").val();
    const root = document.documentElement;
    root.style.setProperty("--f7-panel-backdrop-bg-color", "none");
    root.style.setProperty("--f7-panel-shadow", "none");
  },

  computed: {
    ...mapState("auth", [
      "profile",
      "user",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
      "document",
    ]),
    ...mapState("account", ["userAccount"]),

  },
  methods: {
    reducePageWidth() {
      if (this.isTablet) {
        f7.$("div.userProfilePage.page").css("width", "calc(100% - 418px)");
      }
    },
    resetPageWidth() {
      if (this.isTablet) {
        f7.$("div.userProfilePage.page").css("width", "100%");
      }
    },
    publicStatusChange(account) {
      const toggle = f7.toggle.get(".publicStatus");
      if (toggle.checked) {
        if (account.type === "STANDARD") {
          this.stdAcctPublicStatus = this.$t("profile.accountIsPublic");
        } else {
          this.businessAcctPublicStatus = this.$t("profile.accountIsPublic");
        }
        this.$store.dispatch("account/changePublicStatus", {
          accountId: account.id,
          publicAccount: true,
        });
      } else {
        if (account.type === "STANDARD") {
          this.stdAcctPublicStatus = this.$t("profile.accountNotPublic");
        } else {
          this.businessAcctPublicStatus = this.$t("profile.accountNotPublic");
        }
        this.$store.dispatch("account/changePublicStatus", {
          accountId: account.id,
          publicAccount: false,
        });
      }
    },
    onPaymentLimitChange(values) {
      this.paymentLimit = values;
      console.log("this.paymentLimit ", values);
      this.$store.dispatch("account/setPaymentLimit", {
        accountId: this.userAccount.standard.id,
        paymentLimit: values,
      });
    },
    editProfile() {
      this.f7router.navigate("/user/profile/edit");
      // const url =
      //   `${import.meta.env.VITE_APP_URL}` +
      //   "realms/" +
      //   base_url.keycloakJson.realm +
      //   "/account";
      // if (f7.device.cordova == true) {
      //   cordova.plugins.browsertab.isAvailable(
      //     (result) => {
      //       if (result) {
      //         cordova.plugins.browsertab.openUrl(
      //           url,
      //           (successResp) => {},
      //           (failureResp) => {
      //             console.log("failed to launch browser tab");
      //           }
      //         );
      //       } else {
      //         const ref = cordova.InAppBrowser.open(url, "_self", "location = no");
      //       }
      //     },
      //     (isAvailableError) => {
      //       console.log("failed to query availability of in-app browser tab");
      //     }
      //   );

      // } else {
      //   this.$keycloak.accountManagement();
      // }
    },

    async uploadProofOfId() {
      if (!f7.input.validateInputs(".uploadProofOfId")) {
        return;
      }
      f7.preloader.show("multi");
      this.forms = new FormData();

      for (const content of this.documentsUrl) {
        await this.forms.append("files", content.file);
      }
      await this.$store.dispatch("account/uploadProofOfId", this.forms);
      setTimeout(() => {
        this.documentsUrl = [];
        f7.panel.close(".uploadProofOfIdOpen");
        f7.preloader.hide();
      }, 300);
      this.$store.dispatch("auth/clearDocumentsUrl");
    },
    uploadAvatarLogo(account) {
      let filename;
      if (account.type === "BUSINESS") {
        this.avatar = false;
        filename = "logo";
      } else {
        this.avatar = true;
        filename = "avatar";
      }
      this.forms = new FormData();

      this.forms.append("filename", filename);

      for (const content of this.documentsUrl) {
        if (content.file) {
          this.forms.append("file", content.file, filename);
        }
      }
      this.$store.dispatch("account/updateLogoAvatar", {
        forms: this.forms,
        accountId: account.id,
      });
      // }
      this.$store.dispatch("auth/clearDocumentsUrl");
    },

    getPicturesAvatar() {
      this.fileInput = "userAvatarInput";
      this.avatar = true;
      this.getFiles();
    },
    getPicturesBusiness() {
      this.fileInput = "businessLogoInput";
      this.avatar = false;
      this.getFiles();
    },
    getPicturesIdProof() {
      this.fileInput = "proofOfIninput";
      this.getFiles();
    },

    /*  uploadLogo(accountId) {
      const self = this;

      this.avatar = false;
      this.forms = new FormData();
      const result = this.$refs.cropper.getResult();

      if (result) {
        fetch(result.canvas.toDataURL())
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "logo", { type: "image/png" });
            this.forms.append("file", file);
          });
      } else {
        for (let i = 0; i < this.files.length; i++) {
          this.forms.append("file", this.files[i]);
        }
      }

      if (this.businessAccount.logo === null) {
        self.$store.dispatch("account/uploadLogoAvatar", {
          forms: this.forms,
          accountId: accountId,
        });
      } else {
        self.$store.dispatch("account/updateLogoAvatar", {
          forms: this.forms,
          accountId: accountId,
        });
      }
      this.documentsUrl = [];
    }, */
  },
};
</script>
