<template>
  <f7-page class="dashboard_page withMainToolbar" name="dashboard">
    <!-- :style="{
      background:
        'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',
    }" -->
    <f7-navbar sliding no-shadow v-if="!this.isTablet">
      <f7-nav-title>{{ $t("dashboard.myDashboard") }}</f7-nav-title>
      <f7-nav-right v-if="this.isTablet == false">
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card
          :padding="false"
          class="text-align-center"
          href="/user/profile"
          :title="this.profile.name"
        >
        </f7-card>
        <!-- <f7-link
          v-if="
            this.userAccount.standard &&
            this.userAccount.standard !== '' &&
            this.userAccount.standard.stripeAccountId !== ''
          "
          class="margin"
          @click="reoauth()"
          :text="$t('common.StripeAccountValidateOrUpdate')"
        ></f7-link> -->

        <div class="grid xsmall-grid-cols-1 small-grid-cols-2">
          <a
            href="#"
            @click="goToAccount('standard')"
            v-if="this.userAccount.standard && this.userAccount.standard !== ''"
          >
            <f7-card
              v-if="this.userAccount.standard && this.userAccount.standard.status !== ''"
              style="height: 300px"
              :class="
                this.userAccount.standard.status !== 'ACTIVE'
                  ? 'border-color-red highlighted'
                  : ''
              "
            >
              <f7-card-header class="grid grid-rows-2"
                ><span>{{ this.userAccount.standard.tag }}</span
                ><span style="font-size: small">
                  №: {{ this.userAccount.standard.id }}</span
                ></f7-card-header
              >
              <f7-card-content>
                <!-- <f7-link
                  class="text-align-center"
                  href="#"
                  icon-size="32"
                  icon-ios="f7:tray_2"
                  icon-md="material:account_balance_wallet"
                ></f7-link> -->

                <h4 v-if="this.userAccount.standard.type !== ''">
                  {{ $t("common.accountType") }}:
                  {{ getAccountType(this.userAccount.standard.type) }}
                </h4>
                <h4>
                  {{ $t("common.status") }}:
                  <f7-badge
                    v-if="this.userAccount.standard && this.userAccount.status !== ''"
                    :color="
                      this.userAccount.standard.status === 'ACTIVE'
                        ? 'primary'
                        : 'deeporange'
                    "
                  >
                    {{ getAccountStatus(this.userAccount.standard.status) }}</f7-badge
                  >
                </h4>

                <h4>
                  {{ $t("common.kyc_level") }}:
                  <f7-badge color="primary">{{
                    this.userAccount.standard.kyc_level.split("_")[1]
                  }}</f7-badge>
                </h4>

                <p class="text-align-center" href="#">
                  {{ $t("common.seeDetail") }}
                </p>
              </f7-card-content>
            </f7-card>
          </a>
          <a
            href="#"
            @click="goToAccount('business')"
            v-if="this.userAccount && this.userAccount.business !== null"
          >
            <f7-card
              v-if="
                this.userAccount.business !== null &&
                this.userAccount.business.status !== ''
              "
              :class="
                this.userAccount.business.status !== 'ACTIVE'
                  ? 'border-color-red highlighted'
                  : ''
              "
              style="height: 300px"
            >
              <f7-card-header class="grid grid-rows-2"
                ><span>{{ this.userAccount.business.tag }}</span
                ><span style="font-size: small">
                  №: {{ this.userAccount.business.id }}</span
                ></f7-card-header
              >
              <f7-card-content>
                <!-- <f7-link class="text-align-center" href="#" icon-size="32" icon-ios="f7:square_stack_3d_up"
                  icon-md="material:account_balance"></f7-link> -->
                <h4 v-if="this.userAccount.business.type !== ''">
                  > {{ $t("common.accountType") }}:
                  {{ getAccountType(this.userAccount.business.type) }}
                </h4>
                <h4>
                  {{ $t("common.status") }}:
                  <f7-badge
                    v-if="
                      this.userAccount.business !== null &&
                      this.userAccount.business.status !== ''
                    "
                    :color="
                      this.userAccount.business.status === 'ACTIVE'
                        ? 'primary'
                        : 'deeporange'
                    "
                  >
                    {{ getAccountStatus(this.userAccount.business.status) }}</f7-badge
                  >
                </h4>

                <h4>
                  {{ $t("common.kyc_level") }}:
                  <f7-badge color="primary">{{
                    this.userAccount.business.kyc_level.split("_")[1]
                  }}</f7-badge>
                </h4>

                <p
                  class="text-align-center"
                  href="#"
                  v-if="
                    this.userAccount.business !== null &&
                    this.userAccount.business.status !== ''
                  "
                >
                  <span v-if="this.userAccount.business.status === 'ACTIVE'">
                    {{ $t("common.seeDetail") }}</span
                  >
                  <span v-else>{{ $t("dashboard.activateAccount") }}</span>
                </p>
              </f7-card-content>
            </f7-card>
          </a>
          <a v-else href="/account/business/activate" class="text-align-center">
            <f7-card :style="this.isTablet ? 'height: 300px' : ''">
              <f7-link
                class="text-align-center"
                href="#"
                icon-size="32"
                icon-ios="f7:square_stack_3d_up"
                icon-md="material:account_balance"
                style="top: 50%"
              ></f7-link>
              <p class="text-align-center" href="#" style="top: 50%">
                <span>{{ $t("dashboard.activateAccount") }}</span>
              </p>
            </f7-card>
          </a>
        </div>

        <div
          class="padding-vertical grid grid-cols-2 small-grid-cols-4 text-align-center dashboardGrid"
        >
          <!-- <a href="/bankAccount" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              ios="f7:building_columns"
              md="material:account_balance"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.myBAnckAccounts") }}</h5>
          </a>
          <a href="/pay/cards" class="card card-raised prodCard">
            <f7-icon size="44px" ios="f7:creditcard" md="material:credit_card"></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.myBankCards") }}</h5>
          </a>

          <a href="/bankWire" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              aurora="f7:arrow_right_arrow_left_square"
              ios="f7:arrow_right_arrow_left_square"
              md="material:swap_horiz"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.bankwire") }}</h5>
          </a>

          <a href="/bankCard" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="red"
              aurora="f7:square_arrow_up_on_square"
              ios="f7:square_arrow_up_on_square"
              md="material:add_circle_outline"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.myBankCards") }}</h5>
          </a>

          <a href="/topup/between-accounts" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              aurora="f7:arrow_right_arrow_left_square"
              ios="f7:arrow_right_arrow_left_square"
              md="material:swap_horiz"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.transferBtwAcounts") }}</h5>
          </a>

          <a href="/topup/my-account" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="red"
              aurora="f7:square_arrow_up_on_square"
              ios="f7:square_arrow_up_on_square"
              md="material:add_circle_outline"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.topupMyAcounts") }}</h5>
          </a> -->

          <a href="/user/profile" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              aurora="f7:person_round"
              ios="f7:person_round"
              md="material:person_outline"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.goToMyProfile") }}</h5>
          </a>

          <a href="/recipients" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="red"
              aurora="f7:person_2_square_stack"
              ios="f7:person_2_square_stack"
              md="material:people_outline"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.goToMyRecipients") }}</h5>
          </a>

          <!-- <a href="/card/new" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              aurora="f7:creditcard_fill"
              ios="f7:creditcard_fill"
              md="material:credit_card"
            >
              <f7-badge color="red">+</f7-badge>
            </f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.issueNewCard") }}</h5>
          </a>

          <a href="/card/sold" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="red"
              aurora="f7:creditcard_fill"
              ios="f7:creditcard_fill"
              md="material:credit_card"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.soldCards") }}</h5>
          </a> -->

          <a href="/tontine/new" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              aurora="f7:person_3"
              ios="f7:person_3"
              md="material:group_work"
            >
              <f7-badge color="red">+</f7-badge>
            </f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.createATontine") }}</h5>
          </a>

          <a href="#" @click="getTontineList()" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="red"
              aurora="f7:person_3"
              ios="f7:person_3"
              md="material:group_work"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.myTontines") }}</h5>
          </a>

          <a href="/association/new/association" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="green"
              ios="f7:heart"
              aurora="f7:heart"
              md="material:favorite_border"
            >
              <f7-badge color="red">+</f7-badge>
            </f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.newAssociationcircle") }}</h5>
          </a>

          <a href="/associations" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="green"
              ios="f7:heart"
              aurora="f7:heart"
              md="material:favorite_border"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.myAssociationscircles") }}</h5>
          </a>

          <a href="/savings/new" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              aurora="f7:person_3"
              ios="f7:person_3"
              md="material:group_work"
            >
              <f7-badge color="red">+</f7-badge>
            </f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.createSavingsGroup") }}</h5>
          </a>

          <a href="#" @click="getSavingsList()" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="red"
              aurora="f7:person_3"
              ios="f7:person_3"
              md="material:group_work"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.mySavingsBattles") }}</h5>
          </a>

          <a href="/groupBuy/new" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              aurora="f7:person_3"
              ios="f7:person_3"
              md="material:group_work"
            >
              <f7-badge color="red">+</f7-badge>
            </f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.createGroupBuy") }}</h5>
          </a>

          <a href="#" @click="getGroupBuyList()" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="red"
              aurora="f7:person_3"
              ios="f7:person_3"
              md="material:group_work"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.myGroupBuyProjects") }}</h5>
          </a>

          <a href="/wallet/new" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              aurora="f7:circle_grid_hex"
              ios="f7:circle_grid_hex"
              md="material:grain"
            >
              <f7-badge color="red">+</f7-badge>
            </f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.newCrowdfunding") }}</h5>
          </a>

          <a href="#" @click="getUserWalletList()" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="red"
              aurora="f7:circle_grid_hex"
              ios="f7:circle_grid_hex"
              md="material:grain"
            >
            </f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.myCrowdfunding") }}</h5>
          </a>

          <!-- <f7-row no-gap class="text-align-center">
        <f7-col class="dashboardColBorderRight">
          <a href="/wallet/new/private">

            <f7-icon
              size="44px"

              aurora="f7:archivebox_fill"
              ios="f7:archivebox_fill"
              md="f7:archivebox_fill"
            >
              <f7-badge color="red">+</f7-badge>
            </template>
            <br />{{ $t("dashboard.newMoneypot") }}</a
          >
        </f7-col>
        <f7-col>
          <a href="/wallet/private">

            <f7-icon
              size="44px"

              color="red"
              aurora="f7:archivebox_fill"
              ios="f7:archivebox_fill"
              md="f7:archivebox_fill"
            ></template>
            <br />{{ $t("dashboard.myMoneypots") }}</a
          >
        </f7-col>
      </f7-row>
      <br /> -->

          <a href="/shop/new" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              aurora="f7:cart_fill"
              ios="f7:cart_fill"
              md="material:store"
            >
              <f7-badge color="red">+</f7-badge>
            </f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.newShop") }}</h5>
          </a>

          <a href="/shops" class="card card-raised prodCard">
            <f7-icon
              size="44px"
              color="red"
              aurora="f7:cart_fill"
              ios="f7:cart_fill"
              md="material:store"
            ></f7-icon>
            <h5 class="margin-top-half">{{ $t("dashboard.myShops") }}</h5>
          </a>
        </div>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped>
.dashboardColBorderRight {
  border-right: 1px solid var(--f7-theme-color);
}
</style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Card,
  f7CardContent,
  f7CardHeader,
  f7Icon,
  f7Badge,
  theme,
  f7,
} from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import templatePreloader from "../components/template_preloader.vue";

import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  // mixins: [account],
  components: {
    navbarright: Navbarright,
    templatePreloader,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Card,
    f7CardContent,
    f7CardHeader,

    f7Icon,
    f7Badge,
  },
  data() {
    return { theme, f7, theme };
  },
  mounted() {
    if (this.userAccount === "" && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.isTablet == false) {
      f7.$("div.dashboard_page.page-content").addClass("hide-bars-on-scroll");
    }
    f7.$(".mainToolbar").css("display", "block");
  },
  methods: {
    async goToAccount(accountType) {
      f7.preloader.show("multi");
      if (!this.userAccount) {
        await self.$store.dispatch("account/loadAccount", this.profile.sub);
      }
      setTimeout(() => {
        f7.views.main.router.navigate(
          `/account/${accountType}/${
            accountType === "standard"
              ? this.userAccount.standard.id
              : this.userAccount.business.id
          }`
        );
        f7.preloader.hide();
      }, 300);
    },

    // async goToBusinessAccount() {
    //   const self = this;
    //   f7.preloader.show("multi");
    //   if (!this.userAccount) {
    //     await self.$store.dispatch("account/loadAccount", this.profile.sub);
    //   }
    //   setTimeout(() => {
    //     f7.views.main.router.navigate(
    //       `/account/${this.userAccount.business.type}/${this.userAccount.business.id}`
    //     );
    //     f7.preloader.hide();
    //   }, 300);
    // },
    reoauth() {
      this.$store.dispatch(
        "stripe/stripeOnbordingRedirect",
        this.userAccount.standard.stripeAccountId
      );
    },

    getAccountStatus(status) {
      switch (status) {
        case "ACTIVE":
          return this.$t("common.active");
        case "INACTIVE":
          return this.$t("common.inActive");
        case "KYC_REQUIRED":
          return this.$t("common.kycRequired");
        case "SUSPENDED":
          return this.$t("bankAccount.suspended");
        default:
          return this.$t("bankAccount.inActive");
      }
    },
    getAccountType(type) {
      return type === "STANDARD"
        ? this.$t("common.standard")
        : this.$t("common.business");
    },

    async getTontineList() {
      f7.preloader.show("multi");
      await this.$store.dispatch("tontine/getUserTontinesByuserId", {
        maxNumberOfItem: 6,
        userId: this.profile.sub,
      });
      setTimeout(() => {
        f7.views.main.router.navigate("/tontines");
      }, 300);
      f7.preloader.hide();
    },
    async getUserWalletList() {
      f7.preloader.show("multi");
      await this.$store.dispatch("wallet/getPrivateWalletList", {
        maxNumberOfItem: 6,
        userId: this.profile.sub,
      });
      setTimeout(() => {
        f7.views.main.router.navigate("/wallets/private");
      }, 300);
      f7.preloader.hide();
    },
    getSavingsList() {
      f7.preloader.show("multi");
      this.$store.dispatch("savings/getUserSavingsGroupList", {
        maxNumberOfItem: 6,
        userId: this.profile.sub,
      });
      setTimeout(() => {
        f7.views.main.router.navigate("/savings");
      }, 300);
      f7.preloader.hide();
    },

    getGroupBuyList() {
      f7.preloader.show("multi");
      this.$store.dispatch("groupBuy/getUserGroupBuyList", {
        maxNumberOfItem: 6,
        userId: this.profile.sub,
      });
      setTimeout(() => {
        f7.views.main.router.navigate("/groupBuy");
      }, 300);
      f7.preloader.hide();
    },
  },
  computed: {
    ...mapState("wallet", [
      "wallet",
      "walletList",
      "publicWalletList",
      "privateWalletList",
    ]),
    ...mapState("tontine", ["tontineList", "tontine"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("account", ["userAccount"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),
    primaryaccountUrl() {
      return `/my-transactions/${this.userAccount.standard.id}/primaryTransactionList`;
    },
    businessaccountUrl() {
      return this.userAccount.business && this.userAccount.business.status === "ACTIVE"
        ? `/my-transactions/${this.userAccount.business.id}/businessTransactionList`
        : "/account/business/activate";
    },
    businessColor() {
      if (
        (this.userAccount.business && this.userAccount.business.status !== "ACTIVE") ||
        this.userAccount.business === null
      ) {
        return "color-pink text-color-pink";
      } else {
        return;
      }
    },
  },
};
</script>
